<script setup lang="ts">
import { useUserStore } from "~/store/useUserStore"
const { links } = useAppConfig()
const config = useRuntimeConfig()
const { addToast } = useAppToast()
const userStore = useUserStore()
const userToken: Ref<string | null> = useCookie(config.public.cookies.userToken)

const disconnect = async () => {
    userToken.value = null
    await navigateTo(links.login)
}

const accept = async () => {
    try {
        userStore.user.isTecAllianceCgv = true
        const body = userStore.user
        await $fetch('/api/customers', { method: 'PUT', body })
    } catch(err: any) {
        console.error(err)
        userStore.user.isTecAllianceCgv = false
        addToast("Une erreur est survenue lors de l'approbation des CGV.", 'error')
    }
}
</script>

<template>
    
    <ElementsModal :isOpen="userStore.user?.customerId && !userStore.user.isTecAllianceCgv" :disableCloseIcon="true" :disableCloseOnOutsideClick="true" title="Conditions générales de vente et conditions relatives aux produits (CGV) TecAlliance" class="tecAllianceCgt">
        <template #content>
            <ElementsToolBar>
                <template #right>
                    <ElementsButtonLinear name="Accepter" @click="accept()"/>
                    <ElementsButtonLinear name="Refuser" class="btn-ghost" @click="disconnect()"/>
                </template>
            </ElementsToolBar>
            
            <!-- https://dwnld.aws.tecalliance.com/GTC/GTC-FR.pdf -->
            <!-- converted by xodo.com -->
            <p>Version 2.8 du : 10/05/2024</p>
            <p >Ces CGV réglementent les relations contractuelles entre TecAlliance et ses clients, dans la mesure où aucun accord contractuel individuel n’a été conclu entre les parties.</p>
            <p >Les présentes CGV se composent des sections suivantes :</p>
            <ol>
                <li>
                    <p style="padding-left: 12pt;text-indent: -7pt;text-align: left;"><a href="#bookmark0" class="s3">Conditions générales de vente (page 1)</a></p>
                </li>
                <li>
                    <p style="padding-top: 1pt;padding-left: 12pt;text-indent: -7pt;text-align: left;"><a href="#bookmark7" class="s5">Conditions spéciales de vente TecDoc (page 5)</a></p>
                </li>
                <li data-list-text="3.">
                    <p style="padding-top: 1pt;padding-left: 12pt;text-indent: -7pt;text-align: left;"><a href="#bookmark12" class="s7">Conditions spéciales de vente TecRMI (page 18)</a></p>
                </li>
                <li data-list-text="4.">
                    <p style="padding-top: 1pt;padding-left: 12pt;text-indent: -7pt;text-align: left;"><a href="#bookmark14" class="s9">Conditions spéciales de vente TecCom (page 21)</a></p>
                </li>
                <li data-list-text="5.">
                    <p class="s10" style="padding-top: 1pt;padding-left: 12pt;text-indent: -7pt;text-align: left;"><a href="#bookmark15">Conditions spéciales de vente TecFleet (page 24)</a></p>
                </li>
            </ol>
            <ol id="l2">
                <li data-list-text="1.">
                    <p class="s11" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;"><a name="bookmark0">&zwnj;</a>Conditions générales de vente</p>
                    <ol id="l3">
                        <li data-list-text="1.1.">
                        <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Définitions</h1>
                        <ol id="l4">
                            <li data-list-text="1.1.1.">
                                <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Jour ouvrable : <span class="p">du lundi au vendredi, sauf les jours fériés en République fédérale d&#39;Allemagne ainsi que les 24 et 31 décembre.</span></h1>
                            </li>
                            <li data-list-text="1.1.2.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Force majeure : <span class="p">tout événement imprévisible et inévitable échappant au contrôle de tous les contractants et qui n&#39;aurait pu être évité compte tenu des circonstances avec des moyens appropriés et raisonnables, notamment guerres, guerres civiles, révolutions, tremblements de terre, ouragans, incendies ou pandémies.</span></h1>
                            </li>
                            <li data-list-text="1.1.3.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">IAM/Independent Automotive Aftermarket <span class="p">: Marché de la maintenance et des réparations de véhicules en dehors du réseau de distribution et de services du constructeur automobile, y compris</span></h1>
                                <ul id="l5">
                                    <li data-list-text="-">
                                    <p style="padding-left: 9pt;text-indent: -3pt;text-align: justify;">les ateliers de réparation,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 13pt;text-indent: -8pt;text-align: justify;">les fabricants ou distributeurs d’équipements pour garages</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">automobiles,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -3pt;text-align: left;">les outils ou pièces détachées,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -3pt;text-align: left;">les éditeurs d’informations techniques,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -3pt;text-align: left;">les clubs automobiles, les services de dépannage,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -3pt;text-align: left;">les fournisseurs de services de révision et de contrôle technique,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -3pt;text-align: left;">les organismes de formation de mécaniciens,</p>
                                    </li>
                                    <li data-list-text="-">
                                    <p style="padding-top: 1pt;padding-left: 9pt;text-indent: -4pt;text-align: left;">les fabricants et réparateurs d’équipements destinés à la conversion</p>
                                    </li>
                                </ul>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">des véhicules fonctionnant avec des carburants alternatifs.</p>
                            </li>
                            <li data-list-text="1.1.4.">
                                <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">IAM Europe <span class="p">comprend les pays suivants : Albanie, Allemagne, Andorre, Autriche, Belgique, Bosnie-Herzégovine, Bulgarie, Cité du Vatican, Croatie, Danemark, Espagne, Estonie, Finlande, France, Grande- Bretagne, Grèce, Hongrie, Irlande, Islande, Italie, Lettonie, Liechtenstein, Lituanie,  Luxembourg,  Macédoine,  Malte,  Moldavie,  Monaco, Monténégro, Norvège, Pays-Bas, Pologne, Portugal, République tchèque, Roumanie, Saint-Marin, Serbie, Slovaquie, Slovénie, Suède, Suisse, Turquie, Ukraine.</span></h1>
                            </li>
                            <li data-list-text="1.1.5.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Groupe : <span class="p">Entreprises affiliées au sens des articles 15 et suivants de la loi allemande sur les sociétés anonymes (Aktiengesetz, AktG).</span></h1>
                            </li>
                            <li data-list-text="1.1.6.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Place de marché en ligne <span class="p">: Plateforme de vente sur internet permettant au client lui-même et aux distributeurs tiers agréés de vendre des pièces détachées.</span></h1>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.2.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Champ réglementaire</h1>
                        <ol id="l6">
                            <li data-list-text="1.2.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark1">&zwnj;</a>Les conditions de vente ci-après s’appliquent à l’ensemble des livraisons, prestations et offres de TecAlliance GmbH, Steinheilstraße 10, 85737 Ismaning, Allemagne (ci-après : TecAlliance).</p>
                            </li>
                            <li data-list-text="1.2.2.">
                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark2">&zwnj;</a>Les présentes conditions générales s&#39;appliquent également aux livraisons, prestations et offres des entreprises affiliées à TecAlliance conformément aux art. 15 et ss. de la loi AktG, à condition que ces conditions de vente soient expressément mentionnées dans les offres respectives. Dans ce cas, le partenaire contractuel est la société affiliée mentionnée dans l&#39;offre.</p>
                            </li>
                            <li data-list-text="1.2.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les présentes CGV s&#39;appliquent également aux livraisons, prestations et services de TecAlliance réalisés par des tiers, si les devis respectifs renvoient expressément aux présentes CGV. Dans ce cas, le partenaire contractuel est TecAlliance.</p>
                            </li>
                            <li data-list-text="1.2.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les activités commerciales de TecAlliance sont axées sur les activités avec les entreprises, au sens de l’art. 14 du code civil allemand (BGB). Ces conditions de vente ne s’appliquent pas aux transactions juridiques avec les consommateurs, au sens de l’art. 13 du BGB.</p>
                            </li>
                            <li data-list-text="1.2.5.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les conditions de vente opposées du client ne font pas partie intégrante du contrat.</p>
                            </li>
                            <li data-list-text="1.2.6.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les réglementations divergentes et/ou opposées à ces conditions de vente ne sont effectives que lorsqu’elles ont été convenues sous forme littérale dans le cadre d’un contrat individuel.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.3.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Offre et conclusion du contrat</h1>
                        <ol id="l7">
                            <li data-list-text="1.3.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les offres de TecAlliance sous forme littérale sont fermes, sauf stipulation expresse contraire.</p>
                            </li>
                            <li data-list-text="1.3.2.">
                                <p style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Les offres de TecAlliance peuvent être acceptées dans les six (6)</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">semaines suivant la date de l’offre.</p>
                            </li>
                            <li data-list-text="1.3.3.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par l’acceptation de l’offre de TecAlliance sous forme littérale par le client, un contrat est conclu sur les prestations offertes entre les parties.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.4.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Étendue des prestations</h1>
                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le contenu et l’étendue des prestations à fournir par TecAlliance découlent de l’offre, du descriptif des prestations, du descriptif du projet, des présentes conditions de vente et d’autres réglementations convenues dans le cadre d’un contrat individuel.</p>
                        <ol id="l8">
                            <li data-list-text="1.4.1.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Concession de licences sur des bases de données (Data)</h1>
                                <ol id="l9">
                                    <li data-list-text="1.4.1.1.">
                                    <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Le contenu des prestations est la mise à disposition d’une base de</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">données et de ses contenus selon les réglementations contractuelles.</p>
                                    <ol id="l10">
                                        <li data-list-text="1.4.1.1.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les détails relatifs à la base de données mise à disposition résultent du descriptif de la prestation.</p>
                                        </li>
                                        <li data-list-text="1.4.1.1.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est en droit de doter la base de données et ses contenus de mesures de protection techniques contre les copies non autorisées. Le client n’est pas autorisé à supprimer ou contourner ces mesures de protection.</p>
                                        </li>
                                        <li data-list-text="1.4.1.1.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La base de données ou le contenu des bases de données mis à disposition proviennent en partie de tiers (par ex. fournisseurs de données, prestataires de services). Dans ce cas, le tiers répond seul de l’exactitude de la base de données ou de son contenu.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="1.4.1.2.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Octroi de droits d’exploitation</h1>
                                    <ol id="l11">
                                        <li data-list-text="1.4.1.2.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client reconnaît que la base de données et ses contenus mis à disposition sont des œuvres protégées par le droit d’auteur et tout autre droit de propriété.</p>
                                        </li>
                                        <li data-list-text="1.4.1.2.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client le droit non exclusif, limité dans le temps à la durée du présent contrat, non transmissible et non sous-licenciable de duplication, de diffusion et de mise à disposition publique de la base de données et de ses contenus exclusivement limités aux projets spécifiés dans le descriptif du produit, en vertu des clauses des présentes conditions de vente.</p>
                                        </li>
                                        <li data-list-text="1.4.1.2.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark3">&zwnj;</a>Si la base de données et son contenu ont été acquis sous une licence « Restricted Access », ils peuvent exclusivement être mis à la disposition d’un cercle restreint d’utilisateurs. L’accès à la base de</p>
                                            <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                            <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">données et au contenu de la base de données doit être limité aux utilisateurs que le client connaît, via une connexion ou un mécanisme de protection similaire. Toute autre mise à disposition même partielle de la base de données et de son contenu en dehors du cercle restreint d’utilisateurs est exclue.</p>
                                        </li>
                                        <li data-list-text="1.4.1.2.4.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si la base de données et son contenu ont été acquis sous une licence « Open Access », ils peuvent être publiés sans cette restriction à un cercle d’utilisateurs.</p>
                                        </li>
                                        <li data-list-text="1.4.1.2.5.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est en droit d’employer des prestataires de service dans le cadre des projets décrits dans le descriptif de projet et de leur confier dans ce cadre la base de données et ses contenus. Le client est tenu d&#39;en informer TecAlliance par écrit. En outre, le prestataire de services est tenu de signer le NDA soumis par TecAlliance. TecAlliance accepte que le consentement mentionné précédemment ne peut être refusé ou retardé sans juste motif. TecAlliance donnera le consentement requis dans un délai de 30 jours ouvrables à compter de la réception de la demande du client et du NDA signé par le fournisseur tiers. La responsabilité du client de respecter ces conditions contractuelles n’est pas affectée par cette clause.</p>
                                        </li>
                                        <li data-list-text="1.4.1.2.6.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toute exploitation de la base de données et de ses contenus allant au-delà de l’accord contractuel, ainsi que la remise de ladite base de données et de ses contenus à des tiers sont interdites.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="1.4.1.3.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Obligations du client</h1>
                                    <ol id="l12">
                                        <li data-list-text="1.4.1.3.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’installer un dispositif de protection efficace pour empêcher les modifications, la reproduction non autorisée, la diffusion ou la manipulation des données de TecAlliance (par ex. un pare-feu), en se basant sur les dernières techniques. Le client doit notamment veiller à ce que la modification ou la lecture systématique  de  la  base  de  données,  et  notamment  son téléchargement, soit techniquement impossible.</p>
                                        </li>
                                        <li data-list-text="1.4.1.3.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance a le droit, sans pour autant y être tenue, de contrôler le projet du client pour s’assurer qu’il est bien protégé contre l’exploitation conforme aux termes du contrat. Dans ce but, le client est tenu de garantir à TecAlliance une procédure de test gratuite correspondant au projet.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="1.4.1.4.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Service en ligne / Flux de données</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les conditions suivantes sont valables en complément, si la base de données est mise à disposition par un service en ligne.</p>
                                    <ol id="l13">
                                        <li data-list-text="1.4.1.4.1.">
                                            <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Phase de mise en œuvre</h1>
                                            <ol id="l14">
                                                <li data-list-text="1.4.1.4.1.1.">
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après la conclusion du contrat, le client se voit accorder, sur demande, une phase de mise en œuvre de soixante (60) jours calendaires. Elle débute par la transmission des données de compte correspondantes par TecAlliance.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.1.2.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La phase de mise en œuvre sert à intégrer le service Web dans le système du client. L’utilisation productive du service Web et l’accès public à la base de données sont interdits pendant la phase de mise en œuvre.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.1.3.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Des frais d’installation uniques sont dus pour la phase de mise en œuvre. Les frais de licence ne sont pas dus pendant la phase de mise en œuvre.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.1.4.">
                                                <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">La phase de mise en œuvre n’est pas prise en compte dans</p>
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">la durée minimale du contrat.</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li data-list-text="1.4.1.4.2.">
                                            <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droit de résiliation</h1>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Le client peut résilier le contrat avec effet immédiat au cours des trente</p>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">(30) premiers jours calendaires de la phase de mise en œuvre.</p>
                                        </li>
                                        <li data-list-text="1.4.1.4.3.">
                                            <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Cache</h1>
                                            <ol id="l15">
                                                <li data-list-text="1.4.1.4.3.1.">
                                                <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">L’accès à la base de données et à ses contenus doit</p>
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">impérativement passer par le Web Service en ligne.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.3.2.">
                                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le stockage intermédiaire temporaire des données du service Web (caching) est autorisé, à condition qu’il s’agisse exclusivement de demandes d’utilisateurs et qu’il couvre exclusivement la période allant jusqu’à la prochaine mise à jour des données correspondantes dans le service Web, toutefois pas au-delà de trente</p>
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: justify;">(30) jours.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.3.3.">
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données des &quot;RMI Notes&quot; et les données facturées en fonction de l’utilisation (par ex. par clic, par extraction, par volume) ne peuvent pas être stockées temporairement.</p>
                                                </li>
                                                <li data-list-text="1.4.1.4.3.4.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les données dans le cadre de la facturation et des documents de livraison peuvent être stockées sans limite de temps.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="1.4.1.5.">
                                    <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Violation des conditions de licence / Amende conventionnelle</h1>
                                    <ol id="l16">
                                        <li data-list-text="1.4.1.5.1.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client viole les conditions contractuelles et qu&#39;il n&#39;a pas remédié dans un délai raisonnable à un avertissement afférent de TecAlliance, tous les droits d’exploitation octroyés dans le cadre du présent contrat deviennent immédiatement caduques et reviennent automatiquement à TecAlliance. Dans ce cas, le client doit arrêter immédiatement et entièrement toute exploitation de la base de données, supprimer toutes les copies existant dans ses systèmes informatiques et effacer ou remettre à TecAlliance les éventuelles copies de sauvegarde.</p>
                                        </li>
                                        <li data-list-text="1.4.1.5.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toute violation des conditions contractuelles de la part du client entraîne le paiement d’une amende conventionnelle adéquate à TecAlliance. Le montant de l’amende conventionnelle est déterminé par TecAlliance à sa discrétion. Cependant, il ne sera pas inférieur à 10 000 EUR en cas de violation de la propriété intellectuelle. En cas de litige, le montant de l’amende conventionnelle pourra être contrôlé par le tribunal compétent. Les autres droits de TecAlliance restent inchangés. En cas de demande d’indemnités, le montant de l’amende conventionnelle s’ajoute au montant des indemnités.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="1.4.1.6.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Conséquence de la cessation du contrat</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de cessation du présent contrat, et quelle qu’en soit la raison, le client doit cesser, immédiatement et entièrement, toute exploitation de la base de données, supprimer toutes les copies existant dans ses systèmes et effacer ou remettre à TecDoc les éventuelles copies de sauvegarde.  Cette  obligation  ne  s’applique  pas  tant  que l’enregistrement des données est soumis à une obligation légale de conservation.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="1.4.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Concession de licences sur des solutions de logiciels (Solutions)</h1>
                                <ol id="l17">
                                    <li data-list-text="1.4.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Le contenu des prestations correspond à la mise à</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">disposition d’un logiciel selon les réglementations contractuelles.</p>
                                    </li>
                                    <li data-list-text="1.4.2.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les détails relatifs au logiciel mis à disposition résultent du descriptif de la prestation.</p>
                                    </li>
                                    <li data-list-text="1.4.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est en droit de doter la base de données de mesures de protection techniques contre les copies non autorisées. Le client n’est pas autorisé à supprimer ou contourner ces mesures de protection.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="1.4.3.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Recours à des prestations de services (Consulting &amp; Services) <span class="p">Le contenu des prestations correspond à la mise à disposition de prestations de service par TecAlliance selon l’offre et les réglementations contractuelles.</span></h1>
                                <ol id="l18">
                                    <ol id="l19">
                                    <ol id="l20">
                                        <ol id="l21">
                                            <ol id="l22">
                                                <li data-list-text="1.4.3.1.1.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">Les détails relatifs aux prestations de services réalisées résultent du descriptif de la prestation.</p>
                                                </li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    </ol>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.5.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;"><a name="bookmark4">&zwnj;</a>Contingent de services</h1>
                        <ol id="l23">
                            <li data-list-text="1.5.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas d’accord sur les contingents de prestations, le client acquiert un certain nombre de prestations individuelles par paiement anticipé. La prestation contingentée, le volume du contingent et une éventuelle validité du contingent résultent de l’offre.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                            <li data-list-text="1.5.2.">
                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque prestation individuelle utilisée diminue le contingent convenu d’une (1) prestation. Dès que le contingent atteint zéro (0), il n’est plus possible d’utiliser la prestation.</p>
                            </li>
                            <li data-list-text="1.5.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si un renouvellement automatique du contingent a été convenu, le  contingent  précédemment  acheté  sera  automatiquement réenregistré  après  épuisement  du  contingent  et  la  facture correspondante sera envoyée au client. Le client peut s&#39;opposer à cette nouvelle réservation automatique à tout moment par écrit.</p>
                            </li>
                            <li data-list-text="1.5.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Si une durée de validité du contingent a été convenue, les droits à prestation non utilisés expirent à la fin de la période de validité.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.6.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Prix, facturation, délai de paiement</h1>
                        <ol id="l24">
                            <li data-list-text="1.6.1.">
                                <p style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Les prix mentionnés dans l’offre s’entendent nets de taxes ou de</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">redevances applicables le cas échéant.</p>
                            </li>
                            <li data-list-text="1.6.2.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas d’accord sur des prix basés sur l’exploitation et/ou sur le chiffre d’affaires, le client est tenu de déclarer sous forme littérale les chiffres d’exploitation ou le chiffre d’affaires correspondant aux fins de facturation à TecAlliance, sans que TecAlliance ait besoin de le réclamer, au plus tard le 5 du mois suivant la fin d’un trimestre (soit le 5 janvier, le 5 avril, le 5 juillet et le 5 octobre). S’il existe des raisons justifiées de douter des chiffres déclarés, TecAlliance est alors en droit, à ses propres frais, de faire appel à un expert-comptable pour que celui- ci vérifie les informations données. Si l’on constate lors du contrôle une différence de plus de 3%, les frais de contrôle seront alors à la charge du client.</p>
                            </li>
                            <li data-list-text="1.6.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark4" class="a">Pour  des  prestations  uniques,  la  facturation  a  lieu immédiatement après la fourniture de la prestation. Les contingents de prestation au sens de la clause </a>1.5 sont facturés immédiatement après la conclusion du contrat (prépaiement) Pour les relations contractuelles durables, le décompte est effectué une fois par an. Les années calendaires entamées peuvent le cas échéant être décomptées au prorata.</p>
                            </li>
                            <li data-list-text="1.6.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le délai de paiement est de 30 jours à compter de la réception de la facture.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.7.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Ajustement des prix</h1>
                        <ol id="l25">
                            <li data-list-text="1.7.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Conformément à l&#39;art. 315 du BGB, TecAlliance peut, à sa libre appréciation, ajuster les prix exigibles en vertu du présent contrat à l&#39;évolution du montant total des coûts entrant dans le calcul des prix.</p>
                            </li>
                            <li data-list-text="1.7.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le montant total des coûts comprend en particulier les coûts de la mise à disposition de nos produits (par ex. pour le matériel et les logiciels informatiques, les prestations de service d’hébergement, la gestion de l’infrastructure technique, le service technique), les coûts d’administration des clients (par ex. pour le support, les systèmes de décompte et informatiques), les coûts de prestation de service et de personnel, les coûts divers (par ex. l’administration, l’énergie, les loyers, les systèmes informatiques) et les charges de nature fiscale, ou charges dues aux redevances et autres prélèvements de l’État.</p>
                            </li>
                            <li data-list-text="1.7.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Une modification du prix entre en ligne de compte si et dans la mesure où le montant total des coûts pertinents pour le décompte du prix augmente ou diminue après la conclusion du contrat. Pour la modification des prix, TecAlliance appliquera des critères objectivement praticables dans le cadre de son droit à la désignation des prestations selon le § 315 du Code civil allemand.</p>
                            </li>
                            <li data-list-text="1.7.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les augmentations de prix seront notifiées au client sous forme littérale. La modification est réputée acceptée si le client ne s’y oppose pas dans un délai de six (6) semaines à compter de la notification de la modification sous forme littérale. Si le client s’oppose à la modification dans le délai imparti, TecAlliance se réserve le droit de résiliter le contrat conformément à l’art. 1.8.4.</p>
                            </li>
                            <li data-list-text="1.7.5.">
                                <p style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Les baisses de prix seront notifiées au client sous forme littérale.</p>
                            </li>
                            <li data-list-text="1.7.6.">
                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Indépendamment des règlementations précédentes, TecAlliance est en droit, en cas d’augmentation ou de baisse du montant de la taxe sur le chiffre d’affaires, d’adapter les prix en conséquence, au moment de la modification correspondante, sans que le client ait un droit de recours.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.8.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Durée du contrat et résiliation</h1>
                        <ol id="l26">
                            <li data-list-text="1.8.1.">
                                <p style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Pour des prestations uniques, la durée du contrat s’achève avec</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">la fourniture intégrale des prestations.</p>
                            </li>
                            <li data-list-text="1.8.2.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark4" class="a">La durée du contrat pour les prestations contingentées (article </a>1.5) prend fin avec la fourniture de la dernière prestation individuelle (contingent = 0) ou à expiration d&#39;une période de validité convenue, selon la première éventualité.</p>
                            </li>
                            <li data-list-text="1.8.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark5">&zwnj;</a>Les relations contractuelles durables sont conclues pour une durée indéterminée, toutefois pour une durée de deux (2) ans au moins. À l’issue de la durée contractuelle minimale, le contrat peut être résilié pour la fin de l’année calendaire. Le délai de préavis est de trois (3) mois.</p>
                            </li>
                            <li data-list-text="1.8.4.">
                                <p style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Le droit à résiliation pour motif grave reste inchangé.</p>
                            </li>
                            <li data-list-text="1.8.5.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cas de la vente de l’entreprise du client au moyen d’une cession d’actifs et/ou d’actions, lorsque plus de 25 % des parts sont vendues, TecAlliance est en droit de procéder à une résiliation sans préavis.</p>
                            </li>
                            <li data-list-text="1.8.6.">
                                <p style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">La résiliation, quel qu’en soit le motif, requiert la forme littérale.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.9.">
                        <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Responsabilité</h1>
                        <ol id="l27">
                            <li data-list-text="1.9.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La responsabilité de TecAlliance se limite au préjudice direct moyen, prévisible, inhérent au contrat. La compensation des dommages directs, consécutifs, et notamment du manque à gagner, est exclue. La limite de responsabilité ne s’applique pas en cas d’atteinte à la vie, à l’intégrité corporelle ou à la santé du client, imputable à TecAlliance. Ces termes s’appliquent aussi aux droits du client selon la loi relative à la responsabilité du fait de produits défectueux ou dans le cadre d’une garantie expresse assumée par TecAlliance.</p>
                            </li>
                            <li data-list-text="1.9.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">En outre, la responsabilité des parties dépend des dispositions légales.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.10.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Modifications des CGV</h1>
                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut modifier les présentes CGV avec effet pour l’avenir. La modification est notifiée au client sous forme littérale. La modification est réputée acceptée si le client ne s’y oppose pas dans un délai de six</p>
                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">(6) semaines à compter de la notification de la modification sous forme littérale. Le client sera expressément avisé de cette conséquence lors de la notification de la modification. Si le client s’oppose à la modification envisagée en bonne et due forme, le contrat se poursuit aux conditions antérieures.</p>
                        </li>
                        <li data-list-text="1.11.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Règles sur le contrôle des exportations</h1>
                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">À l&#39;occasion de l&#39;utilisation des produits et services TecAlliance, le client est tenu au respect des législations nationales et internationales en vigueur sur le contrôle des exportations. Le client garantit TecAlliance contre toute action d&#39;autorités ou de tiers à l&#39;encontre de TecAlliance pour non-respect, par le client, des obligations applicables en matière de contrôle des exportations et il s&#39;engage à réparer tous les préjudices subis par TecAlliance et à rembourser toutes les dépenses engagées par TecAlliance dans ce contexte.</p>
                        </li>
                        <li data-list-text="1.12.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Dispositions diverses</h1>
                        <ol id="l28">
                            <li data-list-text="1.12.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’informer immédiatement TecAlliance de toutes les modifications apportées aux données concernant l&#39;entreprise et aux coordonnées pertinentes pour le contrat.</p>
                            </li>
                            <li data-list-text="1.12.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client ne peut céder à des tiers des droits et des obligations découlant directement ou indirectement de ou en rapport avec le présent contrat qu’avec l’accord explicite préalable sous forme littérale de TecAlliance.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                            <li data-list-text="1.12.3.">
                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est en droit de fournir des prestations intégralement ou partiellement par des tiers, en tant que sous-traitant. TecAlliance est responsable de la fourniture de prestations par des sous-traitants comme de ses propres actes.</p>
                            </li>
                            <li data-list-text="1.12.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de fusion du client, le présent contrat s’applique uniquement à la partie du client existante au moment de la signature du contrat. Si le client se divise en plusieurs sociétés, le présent contrat sera transféré à un seul successeur juridique.</p>
                            </li>
                            <li data-list-text="1.12.5.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Un droit de rétention ne peut être exercé par le client que sur le fondement de droits nés du même contrat.</p>
                            </li>
                            <li data-list-text="1.12.6.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le contrat est sujet à l’application exclusive du droit de la République fédérale d’Allemagne, à l’exclusion de la Convention des Nations unies sur les contrats de vente internationale de marchandises du 11 avril 1980 (CVIM).</p>
                            </li>
                            <li data-list-text="1.12.7.">
                                <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Le lieu d’exécution de toutes les livraisons et prestations</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">du contrat est le siège de TecAlliance.</p>
                            </li>
                            <li data-list-text="1.12.8.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark6">&zwnj;</a>En cas de litiges découlant du présent contrat ou en rapport avec celui-ci, les parties s&#39;engagent à tenter de les résoudre en premier lieu par voie de négociation. L&#39;obligation de négocier est réputée respectée, si les parties ont procédé à un échange sur l&#39;objet du litige par écrit, à l&#39;oral ou par téléphone à la suite d&#39;une demande écrite d&#39;ouverture des négociations, présentée par l&#39;une des parties. Si le litige n&#39;est pas entièrement réglé dans les 30 jours suivant la réception de la demande d&#39;ouverture de négociations, il peut faire l&#39;objet d&#39;une action en justice conformément aux dispositions suivantes.</p>
                            </li>
                            <li data-list-text="1.12.9.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark6" class="a">Si le siège du client se trouve au sein de l’Union européenne, tout litige découlant du présent contrat ou en rapport avec celui-ci et qui n’a pas été réglé par des négociations conformément à l’article </a>1.12.8 est soumis aux juridictions ordinaires. Le tribunal compétent est celui de Cologne, si chacune des parties est un commerçant ou une personne morale de droit public.</p>
                            </li>
                            <li data-list-text="1.12.10.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark6" class="a">Si le siège du client se trouve en dehors de l’Union européenne, tout litige découlant du présent contrat ou en rapport avec celui-ci et qui n’a pas été réglé par des négociations conformément à l’article </a>1.12.8 sera tranché en premier et dernier ressort par un tribunal d’arbitrage  auprès  de  l’Institut  allemand  d’arbitrage  (DIS), conformément au règlement d’arbitrage des Nations unies CNUDCI, sans avoir recours aux juridictions ordinaires. La cour d’arbitrage comprend un juge unique. Le siège de l’arbitrage est le siège de TecAlliance. La langue de procédure est l’anglais. Le droit applicable est celui de la République fédérale d’Allemagne.</p>
                            </li>
                            <li data-list-text="1.12.11.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Tous les documents et annexes mentionnés dans les présentes conditions générales de vente font partie intégrante du contrat.</p>
                            </li>
                            <li data-list-text="1.12.12.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si les présentes conditions de vente sont mises à disposition dans d’autres langues que l’allemand, celles-ci ont uniquement un but informatif. La version allemande des présentes conditions de vente est l’unique version contraignante pour les parties au contrat.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.13.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Commandes via la boutique en ligne TecAlliance</h1>
                        <ol id="l29">
                            <li data-list-text="1.13.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Domaine d’application</h1>
                                <ol id="l30">
                                    <li data-list-text="1.13.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">TecAlliance exploite une plateforme de vente sur Internet (boutique en ligne).</p>
                                    </li>
                                    <li data-list-text="1.13.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si les conditions de cette section diffèrent des autres conditions générales, les conditions de cette section s’appliquent aux commandes passées par le client dans la boutique en ligne.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="1.13.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Offre et conclusion du contrat</h1>
                                <ol id="l31">
                                    <li data-list-text="1.13.2.1.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque commande du client via la boutique en ligne est considérée comme une proposition du client à TecAlliance visant à établir un contrat pour les produits commandés.</p>
                                    </li>
                                    <li data-list-text="1.13.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dès réception de la commande par TecAlliance, le client recevra un e-mail confirmant la réception de la commande par TecAlliance et comprenant un récapitulatif de la commande (confirmation de commande).</p>
                                    </li>
                                    <li data-list-text="1.13.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">La confirmation de commande vaut acceptation de l’offre</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">par le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="1.13.3.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Abonnement</h1>
                                <ol id="l32">
                                    <li data-list-text="1.13.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Certains produits de la boutique en ligne peuvent être commandés sous forme d’abonnement. Le client a la possibilité de le spécifier lors du processus de commande.</p>
                                    </li>
                                    <li data-list-text="1.13.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le fait de s&#39;abonner vaut établissement d’une obligation à long terme d’une durée de douze (12) mois.</p>
                                    </li>
                                    <li data-list-text="1.13.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’abonnement est automatiquement prolongé pour une période de douze (12) mois s’il n’a pas été résilié avant expiration de la période contractuelle dans l’espace client de la boutique en ligne.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="1.13.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Livraison</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données d’accès aux produits commandés seront mises à la disposition du client dans l’espace client de la boutique en ligne après paiement intégral.</p>
                            </li>
                            <li data-list-text="1.13.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Paiement et date d’échéance</h1>
                                <ol id="l33">
                                    <li data-list-text="1.13.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Le paiement s’effectue via l’un des moyens de paiement</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">proposés lors de la procédure de commande.</p>
                                    </li>
                                    <li data-list-text="1.13.5.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les frais sont redevables à compter de la conclusion du contrat.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="1.14.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Utilisation des données générées par les utilisateurs</h1>
                        <ol id="l34">
                            <li data-list-text="1.14.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est autorisée à utiliser toutes les données générées lors de l&#39;utilisation des produits TecAlliance (données générées par les utilisateurs) à ses propres fins pour tous les produits actuels et futurs de TecAlliance et à les commercialiser.</p>
                            </li>
                            <li data-list-text="1.14.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Cela comprend notamment les données transmises à TecAlliance par les utilisateurs eux-mêmes, les données générées lors de l&#39;utilisation des produits TecAlliance, les fichiers journaux et les statistiques.</p>
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Sont expressément exclues de cette autorisation les données personnelles au sens du RGPD. Le traitement des données personnelles est décrit de manière détaillée dans la déclaration de protection des données de TecAlliance.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="2.">
                    <p class="s12" style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: left;"><a name="bookmark7">&zwnj;</a>Conditions spéciales de vente TecDoc</p>
                    <ol id="l35">
                        <li data-list-text="2.1.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;"><a name="bookmark8">&zwnj;</a>Catalogue Data</p>
                        <ol id="l36">
                            <li data-list-text="2.1.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le contrat stipule expressément que les données des articles contenues dans la base de données « TecDoc Catalogue Data » proviennent des fournisseurs de données et que TecAlliance ne peut s’assurer que les données sont exactes, complètes et d’actualité.</p>
                            </li>
                            <li data-list-text="2.1.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue de l’exploitation</h1>
                                <ol id="l37">
                                    <li data-list-text="2.1.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le droit d’exploitation du client englobe l’exploitation des données des articles des marques convenues dans les langues convenues. Nous attirons l’attention du client sur le fait que certaines données font l’objet d’une restriction dans certains pays. L’exploitation des données en dehors des pays autorisés est de la seule responsabilité du client.</p>
                                    </li>
                                    <li data-list-text="2.1.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La base de données doit être utilisée exclusivement en liaison avec des pièces neuves, des pièces réusinées ou des pièces de rechange. Les pièces réusinées et les pièces de rechange sont des pièces d’occasion réusinées portant une marque commerciale ou bien la marque de l’entreprise de réusinage et dont les normes de qualité, le fonctionnement et la durée de vie ne diffèrent pas de manière significative de ceux des pièces neuves.</p>
                                    </li>
                                    <li data-list-text="2.1.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il est interdit d’utiliser les données pour le commerce de pièces d’occasion. Les pièces d’occasion sont des pièces qui sont réutilisées sans qu’elles aient été réusinées par le fabricant et sans porter la marque du constructeur automobile ou de l’équipementier d’origine.</p>
                                    </li>
                                    <li data-list-text="2.1.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il est interdit d’utiliser les données d&#39;articles pour des pièces détachées d&#39;origine du constructeur automobile. Les pièces détachées originales sont les pièces munies de la marque du constructeur automobile.</p>
                                    </li>
                                    <li data-list-text="2.1.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client peut afficher les données des articles uniquement lorsque ceux-ci sont effectivement commercialisés par ses soins. Il suffit alors que les articles affichés existent dans sa gamme de produits. La non-disponibilité provisoire d’un article ne joue ici aucun rôle.</p>
                                    </li>
                                    <li data-list-text="2.1.2.6.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d&#39;afficher au minimum les données suivantes pour chaque article : marque du fabricant, référence du fabricant, éventuelles restrictions du produit. Ces informations doivent être facilement accessibles dans le contexte direct des informations sur l’article.</p>
                                    </li>
                                    <li data-list-text="2.1.2.7.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les informations et les photos complémentaires relatives à un article (par ex. liens avec les numéros OEM, informations techniques, informations de montage, cotes) doivent être utilisées exclusivement en lien avec chaque article.</p>
                                    </li>
                                    <li data-list-text="2.1.2.8.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où les fabricants ont indiqué des références croisées vers les numéros des constructeurs automobiles ou vers des produits concurrents, celles-ci peuvent être transmises uniquement dans l’ordre établi. Le client n’est pas autorisé à procéder à des référencements croisés qui ne sont pas déjà disponibles dans la base de données.</p>
                                    </li>
                                    <li data-list-text="2.1.2.9.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark3" class="a">L’exploitation de la base de données TecDoc Catalogue Data et des contenus de la base de données pour les annonces publicitaires  (par  ex.  bannière  publicitaire,  reciblage,  lettre d’informations) ou pour tout autre canal marketing (par ex. comparateurs de prix, plateformes de test de produits, plateformes de réseaux sociaux) est autorisée pour les projets décrits dans le descriptif du projet. Ceci ne s’applique pas en cas de licence « Restricted Access » au sens de l’article </a><a href="#bookmark3">1.4.1.2.3.</a></p>
                                    </li>
                                    <li data-list-text="2.1.2.10.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L&#39;utilisation de la base de données TecDoc Catalogue Data et des contenus de la base de données pour une distribution directe sur des plateformes tierces (par exemple des plateformes de vente, des places de marché en ligne ou des canaux de distribution similaires) n&#39;est autorisée que si la plateforme tierce est licenciée par TecAlliance et est mentionnée dans le cadre du descriptif du projet du client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.1.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Obligations d’information</h1>
                                <ol id="l38">
                                    <li data-list-text="2.1.3.1.">
                                    <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Logo « TecDoc Inside »</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’afficher le logo « TecDoc Inside » mis à disposition par TecAlliance après la conclusion du contrat, en couleur ou en noir et blanc, sur la page d’accueil de la boutique en ligne et/ou sur l’emballage et/ou dans l’éventail de produits proposés sur le catalogue papier et/ou tout autre support publiant la base de données. Il est interdit de modifier le logo. Seule la taille du logo peut être modifiée, mais les proportions des pages doivent rester les mêmes, et la largeur ne doit pas être inférieure à 100 pixels, soit 3 cm.</p>
                                    </li>
                                    <li data-list-text="2.1.3.2.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Remarque sur les droits d’auteur</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="http://www.tecalliance.net/" class="a" target="_blank">Le client est tenu d’afficher la remarque sur les droits d’auteur telle que publiée sur https://</a>www.tecalliance.net/ copyright-note/ sur la page d’accueil de la boutique en ligne et/ou sur l’emballage et/ou dans l’éventail de produits proposés sur le catalogue papier et/ou tout autre support publiant la base de données TecDoc Catalogue Data ou ses contenus. Le texte de la remarque doit figurer dans la langue choisie pour le projet. Il est interdit de modifier ce texte. La taille des caractères doit être d’au moins 10 points. La couleur du texte doit nettement se détacher du fond.</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="http://www.tecalliance.net/" class="a" target="_blank">Sinon, il est également possible de s’acquitter de cette obligation via l’interconnexion du logo « TecDoc Inside » avec la page https://</a>www.tecalliance.net/ copyright-note/.</p>
                                    </li>
                                    <li data-list-text="2.1.3.3.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Informations complémentaires</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de signaler à tous les usagers qu’ils doivent se rapporter à des informations complémentaires, le cas échéant, afin de vérifier que la pièce identifiée dans la base de données correspond effectivement à la pièce recherchée, et qu’elle est bien adaptée au modèle de la voiture concernée. La formulation de l’information n’est pas précisée par TecAlliance.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.1.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Autres obligations du client</h1>
                                <ol id="l39">
                                    <li data-list-text="2.1.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client devra s’assurer que les données publiées soient immédiatement mises à jour et représentées correctement et dans leur intégralité. Le client doit indiquer la version et la validité des données correspondantes.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.2.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Catalogue Data (Place de marché)</p>
                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark8" class="a">Si le produit TecDoc Catalogue Data est utilisé pour une place de marché en ligne, les conditions suivantes s&#39;appliquent en complément à l’article </a><a href="#bookmark8">2.1.</a></p>
                        <ol id="l40">
                            <li data-list-text="2.2.1.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue de l’exploitation</h1>
                                <ol id="l41">
                                    <li data-list-text="2.2.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le droit d&#39;exploitation du client est limité à l&#39;utilisation de la base de données « TecDoc Catalogue Data » dans le cadre de la place de marché en ligne convenue. Tout autre usage, notamment la mise en œuvre de projets autres que les solutions décrites dans le présent contrat, n&#39;est pas concerné.</p>
                                    </li>
                                    <li data-list-text="2.2.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les droits de diffusion et/ou de publication des données TecDoc dans des pays tiers sur la place marché en ligne exigent la signature d&#39;un contrat de licence entre le pays tiers concerné et TecAlliance. Il convient également de signaler la transmission des données à TecAlliance par écrit.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.3.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Reference Data</p>
                        <ol id="l42">
                            <li data-list-text="2.3.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue de l’exploitation</h1>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                <ol id="l43">
                                    <li data-list-text="2.3.1.1.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le droit d’exploitation convenu dans le contrat autorise l’exploitation des données de référence pour les régions sélectionnées dans les langues sélectionnées. Nous attirons l’attention du client sur le fait que certaines données font l’objet d’une restriction dans certains pays.</p>
                                    </li>
                                    <li data-list-text="2.3.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L&#39;utilisation des données à des fins non contractuelles, notamment l&#39;association des données avec d&#39;autres données pour fournir certaines prestations de services propres, est soumise à l&#39;approbation préalable de TecAlliance par écrit.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.3.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Obligations d’information</h1>
                                <ol id="l44">
                                    <li data-list-text="2.3.2.1.">
                                    <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Logo « TecDoc Inside »</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’afficher le logo « TecDoc Inside » mis à disposition par TecAlliance après la conclusion du contrat, en couleur ou en noir et blanc, sur la page d’accueil de la boutique en ligne et/ou sur l’emballage et/ou dans l’éventail de produits proposés sur le catalogue papier et/ou tout autre support publiant la base de données. Il est interdit de modifier le logo. Seule la taille du logo peut être modifiée, mais les proportions des pages doivent rester les mêmes, et la largeur ne doit pas être inférieure à 100 pixels, soit 3 cm.</p>
                                    </li>
                                    <li data-list-text="2.3.2.2.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Remarque sur les droits d’auteur</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="http://www.tecalliance.net/de/copyright-note/" class="a" target="_blank">Le client est tenu d’afficher la remarque sur les droits d’auteur comme prévue sur https://</a>www.tecalliance.net/de/copyright-note/ sur la page d’accueil de la boutique en ligne et/ou sur l’emballage et/ou dans l’éventail de produits proposés sur le catalogue papier et/ou tout autre support publiant les données TecDoc. Le texte de la remarque doit figurer dans la langue choisie pour le projet. Il est interdit de modifier ce texte. La taille des caractères doit être d’au moins 10 points. La couleur du texte doit nettement se détacher du fond. Sinon, il est également possible de s’acquitter de cette obligation via l’interconnexion du logo</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="http://www.tecalliance.net/de/copyright-note/" class="a" target="_blank">« TecDoc     Inside »     avec     la     page https://</a><a href="http://www.tecalliance.net/de/copyright-note/" target="_blank">www.tecalliance.net/de/copyright-note/.</a></p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.4.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">VIN Catalogue – TRUCK</p>
                        <ol id="l45">
                            <li data-list-text="2.4.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données fournies proviennent des constructeurs automobiles respectifs, et TecAlliance ne peut s’assurer que ces données soient exactes, complètes et d’actualité.</p>
                            </li>
                            <li data-list-text="2.4.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Conditions d’utilisation</h1>
                                <ol id="l46">
                                    <li data-list-text="2.4.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Les données du constructeur DAF ne peuvent être utilisées</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">qu’au sein de l’UE et de l’Espace économique européen (EEE).</p>
                                    </li>
                                    <li data-list-text="2.4.2.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données des constructeurs Renault et Volvo ne peuvent être utilisées qu’au sein de l’Espace économique européen (EEE).</p>
                                    </li>
                                    <li data-list-text="2.4.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Les données des constructeurs Scania et IVECO ne</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">peuvent être utilisées qu’au sein de l’UE.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.4.3.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Remarques des constructeurs</h1>
                                <ol id="l47">
                                    <li data-list-text="2.4.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">DAF Trucks : la publication du catalogue TecDoc VIN - Truck s’effectue sans la coopération et l’approbation de DAF Trucks. Les informations contenues dans le catalogue TecDoc VIN - Truck peuvent ne pas représenter de manière précise ou complète les informations que DAF Trucks publie. Par conséquent, DAF Trucks ne peut être tenu responsable des informations contenues dans le catalogue TecDoc VIN - Truck, ni de la sécurité, de la fiabilité, de la consommation de carburant ou des niveaux d’émission de gaz d’échappement des véhicules entretenus ou réparés sur la base des informations contenues dans le catalogue TecDoc VIN OE - Truck.</p>
                                    </li>
                                    <li data-list-text="2.4.3.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: left;">MAN : sous licence MAN Truck &amp; Bus.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.5.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Vehicle in Operation, OE Data</p>
                        <ol id="l48">
                            <li data-list-text="2.5.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <ol id="l49">
                                    <li data-list-text="2.5.1.1.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données mises à disposition proviennent partiellement de sources tierces, pour lesquelles TecAlliance ne peut assumer aucune garantie quant à leur exactitude. Le client reconnaît que la base de données et ses contenus mis à disposition peuvent également contenir des estimations et des hypothèses fondées. Le client dégage donc TecAlliance de toute responsabilité vis-à-vis des tiers qui pourraient souffrir d’un préjudice par l’exploitation des données livrées par TecAlliance.</p>
                                    <ol id="l50">
                                        <ol id="l51">
                                            <ol id="l52">
                                                <li data-list-text="2.5.2">
                                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Conditions relatives aux licences</h1>
                                                <ol id="l53">
                                                    <li data-list-text="2.5.2.1.">
                                                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de transmettre correctement et intégralement les données OE remises par TecAlliance, à savoir le n° HMD, les types K/N, les attributs Vehicle/Articles, les liens TA entre le numéro HMD et/ou les types K/N et les numéros OE (ci-après désignés sous le nom de Données OE), sauf disposition contraire écrite dans les présentes conditions de vente ou dans d’autres accords.</p>
                                                    </li>
                                                    <li data-list-text="2.5.2.2.">
                                                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de transmettre correctement et intégralement les données VIO remises par TecAlliance, à savoir le n° HMD, les types K/N, les attributs Vehicle/Articles, les liens TA entre le numéro HMD et/ou les types K/N et les données de base Vehicles in Operation (ci-après désignés sous le nom de Données VIO ), sauf disposition contraire écrite dans les présentes conditions de vente ou dans d’autres accords.</p>
                                                    </li>
                                                    <li data-list-text="2.5.2.3.">
                                                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client peut exploiter les données OE et/ou les données VIO en interne pour améliorer et enrichir sa propre base de données. L’enrichissement ou la combinaison des données avec d’autres données entraînant une désanonymisation ou une réidentification n’est pas autorisé.</p>
                                                    </li>
                                                    <li data-list-text="2.5.2.4.">
                                                        <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">TecAlliance concède au client un droit d’usage simple sur</p>
                                                    </li>
                                                </ol>
                                                </li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">les données PE et/ou VIO.</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">2.5.2.5   Le client reconnaît que la base de données et ses contenus mis à disposition sont des œuvres protégées par le droit d’auteur et tout autre droit de propriété. Toute utilisation non prévue par le contrat, notamment la distribution, la reproduction, la diffusion ou l’accès public des données OE et/ou VIO à des tiers est interdite.</p>
                                    <ol id="l54">
                                        <ol id="l55">
                                            <ol id="l56">
                                                <li data-list-text="2.5.3.">
                                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Conséquence de la cessation du contrat</h1>
                                                <ol id="l57">
                                                    <li data-list-text="2.5.3.1.">
                                                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de résiliation du contrat, quelle qu&#39;en soit la raison, le client doit cesser immédiatement et complètement l&#39;utilisation de la base de données et supprimer les données OE et VIO.</p>
                                                    </li>
                                                    <li data-list-text="2.5.3.2.">
                                                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La cessation du contrat n’affecte pas le droit d’utiliser la référence des articles que le client à générée sur la base des données OE et/ou VIO.</p>
                                                    </li>
                                                </ol>
                                                </li>
                                            </ol>
                                        </ol>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.6.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Individual Truck OE Datapackage via VIN</p>
                        <ol id="l58">
                            <li data-list-text="2.6.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données fournies proviennent des constructeurs respectifs, et TecAlliance ne peut s’assurer que ces données sont exactes, complètes et actuelles.</p>
                            </li>
                            <li data-list-text="2.6.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Étendue de l’exploitation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données fournies peuvent être utilisées exclusivement à des fins internes, pour la publication dans les systèmes d’information du client et pour la publication dans les systèmes de TecAlliance. Toute autre publication et/ou transmission à des tiers est expressément interdite.</p>
                            </li>
                            <li data-list-text="2.6.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Conditions d’utilisation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">2.6.3.1.1.  Les données du constructeur DAF ne peuvent être utilisées</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">qu’au sein de l’UE et de l’Espace économique européen (EEE).</p>
                                <ol id="l59">
                                    <ol id="l60">
                                    <ol id="l61">
                                        <ol id="l62">
                                            <li data-list-text="2.6.3.2.">
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données des constructeurs Renault et Volvo ne peuvent être utilisées qu’au sein de l’Espace économique européen (EEE).</p>
                                            </li>
                                            <li data-list-text="2.6.3.3.">
                                                <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Les données des constructeurs Scania et IVECO ne</p>
                                            </li>
                                        </ol>
                                    </ol>
                                    </ol>
                                </ol>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">peuvent être utilisées qu’au sein de l’UE.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                            <li data-list-text="2.6.4.">
                                <h1 style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Remarques des constructeurs</h1>
                                <ol id="l63">
                                    <li data-list-text="2.6.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">DAF Trucks : la publication du catalogue TecDoc VIN - Truck s’effectue sans la coopération et l’approbation de DAF Trucks. Les informations contenues dans le catalogue TecDoc VIN - Truck peuvent ne pas représenter de manière précise ou complète les informations que DAF Trucks publie. Par conséquent, DAF Trucks ne peut être tenu responsable des informations contenues dans le catalogue TecDoc VIN - Truck, ni de la sécurité, de la fiabilité, de la consommation de carburant ou des niveaux d’émission de gaz d’échappement des véhicules entretenus ou réparés sur la base des informations contenues dans le catalogue TecDoc VIN OE - Truck.</p>
                                    </li>
                                    <li data-list-text="2.6.4.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: left;">MAN : sous licence MAN Truck &amp; Bus.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.7.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;"><a name="bookmark9">&zwnj;</a>Catalogue</p>
                        <ol id="l64">
                            <li data-list-text="2.7.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <ol id="l65">
                                    <li data-list-text="2.7.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le contrat stipule expressément que les données des articles contenus dans le logiciel « TecDoc Catalogue » proviennent de fournisseurs de données, et que TecAlliance ne peut s’assurer que les données sont exactes, complètes et d’actualité.</p>
                                    </li>
                                    <li data-list-text="2.7.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données affichées après avoir sélectionné un certain pays ne sont valables que pour ce pays. Les données affichées dans le logiciel perdent leur validité dès la parution de la version suivante du logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.7.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l66">
                                    <li data-list-text="2.7.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client reconnaît que le logiciel mis à sa disposition concerne des œuvres protégées par des droits d’auteur et des droits voisins selon la loi allemande sur le droit d’auteur (UrhG).</p>
                                    </li>
                                    <li data-list-text="2.7.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client le droit non exclusif, limité à la durée du présent contrat, non transmissible et non sous-licenciable d’utiliser le logiciel selon les règlementations des présentes conditions de vente.</p>
                                    </li>
                                    <li data-list-text="2.7.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il n’est pas autorisé à exploiter le logiciel et/ou ses parties au-delà de ce qui a été convenu contractuellement. En particulier, le client n’est pas autorisé à faire utiliser le logiciel et/ou des parties du logiciel intégralement ou partiellement par des tiers ou à le rendre accessible à des tiers, le reproduire ou le diffuser, le décompiler ou le désassembler, dans la mesure où cela n’est pas autorisé expressément dans la loi sur le droit d’auteur.</p>
                                    </li>
                                    <li data-list-text="2.7.2.4.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">L’exploitation du logiciel et/ou des données qui y sont</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">contenues est autorisée exclusivement pour le propre usage du client.</p>
                                    </li>
                                    <li data-list-text="2.7.2.5.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’installation d’un support de données dans un réseau propre à l’entreprise du client est autorisée. L’acquisition d’une licence autorise à accéder au catalogue, à partir d’un poste de travail. L’accès par plusieurs postes de travail est autorisé uniquement après l’acquisition d’une licence correspondante.</p>
                                    </li>
                                    <li data-list-text="2.7.2.6.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Toute exploitation du logiciel allant au-delà de l’accord</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">contractuel, ainsi que la remise de ce logiciel à des tiers, sont interdites.</p>
                                    </li>
                                    <li data-list-text="2.7.2.7.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Les données du logiciel ne doivent pas être reproduites</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">et/ou rendues accessibles publiquement sans l’accord de TecAlliance.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.8.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Catalogue White Label</p>
                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark9" class="a">Si la solution Catalogue TecDoc est proposée comme un produit White Label (TecDoc Catalogue White-Label Solution ; TecDoc Catalogue White-Label Solution &amp; Trade Module ; TecDoc Catalogue Reseller Solution ; TecDoc VIN Catalogue Car/Truck), les dispositions suivantes s’appliquent en plus du point </a><a href="#bookmark9">2.7.</a></p>
                        <ol id="l67">
                            <li data-list-text="2.8.1.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Phase de mise en œuvre</h1>
                                <ol id="l68">
                                    <li data-list-text="2.8.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La phase de mise en œuvre sert à adapter le produit White Label aux exigences du client. L’utilisation productive du produit White Label et l’accès public à la base de données sont interdits pendant la phase de mise en œuvre.</p>
                                    </li>
                                    <li data-list-text="2.8.1.2.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">La phase de mise en œuvre commence avec la conclusion du contrat et s’étend sur trente (30) jours calendaires.</p>
                                    </li>
                                    <li data-list-text="2.8.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Des frais d’installation uniques sont dus pour la phase de mise en œuvre. Les frais de licence ne sont pas dus pendant la phase de mise en œuvre.</p>
                                    </li>
                                    <li data-list-text="2.8.1.4.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">La phase de mise en œuvre n’est pas prise en compte dans</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">la durée minimale du contrat.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.8.2.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droit de résiliation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Le client peut résilier le contrat avec effet immédiat au cours des</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">quatorze (14) premiers jours calendaires de la phase de mise en œuvre.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.9.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Appli mobile Catalogue</p>
                        <ol id="l69">
                            <li data-list-text="2.9.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l70">
                                    <li data-list-text="2.9.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance autorise le client à utiliser cette application, selon ses besoins, sur un terminal mobile lui appartenant ou qu&#39;il possède. Les conditions d&#39;utilisation affichées dans Store s&#39;appliquent également. Le client ne pas modifier, dupliquer, publier, concéder des licences ou vendre cette application ou toute information liée à l’application ou au logiciel. De plus, le client ne peut louer les droits de cette application, les mettre en bail ou les transmettre d&#39;une autre manière. L’utilisation de cette application s’effectue exclusivement dans le respect de toutes les lois en vigueur. Si le client viole l’une des disposition  des  présentes  conditions  d’utilisation,  il  perd immédiatement son droit d’utilisation sur cette application.</p>
                                    </li>
                                    <li data-list-text="2.9.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il peut utiliser gratuitement la version de base de l’application (« version light »). La version light lui offre un accès illimité aux  fonctions  TecIdentify, Réseaux  sociaux,  Paramétrages  et Informations. Les autres fonctions de l’application sont limitées dans la version light ou ne sont pas utilisables. Pour utiliser la version illimitée (version premium) et régulièrement actualisée de l’application, il faut acquérir un abonnement payant. Il est possible de prendre un abonnement directement sur l&#39;application.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.9.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue de l’utilisation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance détient toutes les actions, droits et titres sur cette application. Le client n&#39;a pas le droit de modifier, d&#39;adapter, de traduire cette application, de créer des œuvres dérivées, de la décompiler, de procéder à de la rétro-ingénierie, de la désassembler ou de tenter, de quelque manière que ce soit, d&#39;accéder au code source de cette application, sauf autorisation expresse.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.10.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Licence de fournisseur de données</p>
                        <ol id="l71">
                            <li data-list-text="2.10.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l72">
                                    <li data-list-text="2.10.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est soit un fabricant, soit un fournisseur de produits présentant la qualité des pièces détachées originales distribuées dans l’IAM. Un client est un fournisseur uniquement s’il propose des produits fabriqués par un fournisseur tiers pour le compte du fournisseur, le fournisseur étant le propriétaire légitime du produit. Ainsi, le client met ses produits à la disposition de TecAlliance en sa qualité de propriétaire des données des articles.</p>
                                    </li>
                                    <li data-list-text="2.10.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance intègre les données mises à disposition par le client dans la base de données TecDoc à l’aide des programmes adéquats, puis les met à disposition de l’IAM sous forme numérique, dans un format défini par TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.10.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark10" class="a">TecAlliance est uniquement tenue et le client uniquement habilité à intégrer dans la base de données TecDoc les données des produits affectées à un nom de marque (ci-après la « marque ») qui remplissent les critères d’enregistrement d’une marque en vigueur au moment de la conclusion du contrat (cf. article </a>2.10.2.).</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 8pt;text-align: justify;">2.10.1.4  D’autres marques peuvent être intégrées en vertu d’un</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">accord sous forme littérale, contre une rémunération à convenir.</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <ol id="l73">
                                        <ol id="l74">
                                            <ol id="l75">
                                                <ol id="l76">
                                                <li data-list-text="2.10.1.5.">
                                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client garantit qu’il fournit exclusivement à TecAlliance les données des produits provenant de sites de production sur lesquels existe un système de gestion de la qualité et que seuls ces produits seront présentés ou distribués sur l’IAM par TecAlliance.</p>
                                                </li>
                                                <li data-list-text="2.10.1.6.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le client doit en apporter la preuve en remettant un certificat du fabricant au moins pour le secteur production.</p>
                                                </li>
                                                <li data-list-text="2.10.1.7.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client garantit que les données mises à disposition ne sont pas destinées à une clientèle limitée du client. Les données qui ne sont destinées qu’à un cercle déterminé d’acheteurs du client sont exclues des obligations de TecAlliance, notamment en matière de publication et de diffusion.</p>
                                                </li>
                                                <li data-list-text="2.10.1.8.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le format des données et le mode de transmission des données sont déterminés par TecAlliance en tenant compte des normes industrielles en vigueur et les modifications sont communiquées au client en temps utile.</p>
                                                </li>
                                                <li data-list-text="2.10.1.9.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où TecAlliance met à la disposition du client un logiciel pour la fourniture de données, cela se fait selon les conditions de ces CGV.</p>
                                                </li>
                                                <li data-list-text="2.10.1.10.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client répond seul de la sauvegarde des données. TecAlliance ne conserve dans ses systèmes que la dernière version des données fournies.</p>
                                                </li>
                                                </ol>
                                            </ol>
                                        </ol>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;"><a name="bookmark10">&zwnj;</a>Critères d’enregistrement d’une marque</h1>
                                <ol id="l77">
                                    <li data-list-text="2.10.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client assure être le propriétaire de la marque à enregistrer. La marque doit être enregistrée dans les pays concernés par les rapports sur les ventes. Si le client n’est pas le propriétaire de la marque, il garantit qu’il a l’autorisation du propriétaire de la marque d’utiliser celle-ci dans tous les pays cibles. Une autorisation écrite doit être présentée à TecAlliance à sa demande avant la conclusion du contrat ou avant toute intégration d’une autre marque.</p>
                                    </li>
                                    <li data-list-text="2.10.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client assure que la marque à enregistrer, soit en tant que marque de registre et/ou d’usage, dispose d’une protection de marque au sens de l’article 4 de la loi allemande sur les marques ou de la législation locale respective ayant un contenu réglementaire analogue.</p>
                                    </li>
                                    <li data-list-text="2.10.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le client s’engage à respecter les règles suivantes concernant l’utilisation des marques au sein de TecDoc :</p>
                                    <ol id="l78">
                                        <li data-list-text="2.10.2.3.1.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Un nom de marque composé de plusieurs marques distinctes ne peut pas être utilisé dans TecDoc.</p>
                                        </li>
                                        <li data-list-text="2.10.2.3.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il est interdit de placer la raison sociale avant le nom de la marque afin, éventuellement, de monter dans l’ordre alphabétique du fichier de données TecDoc.</p>
                                        </li>
                                        <li data-list-text="2.10.2.3.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il est interdit de renvoyer à des marques non enregistrées dans TecDoc dans des champs de texte édités par le client dans le cadre du traitement des données.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.10.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Seule la dénomination de l&#39;article est apte à désigner une marque enregistrée dans TecDoc au sens d&#39;une gamme de produits. En cas de renvoi à une marque concurrente (qui doit être enregistrée auprès de TecDoc) comme gamme de produits, il convient de faire précéder le nom de la marque concurrente de la mention « genuine ».</p>
                                    </li>
                                    <li data-list-text="2.10.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client s’engage à retirer du fichier de données toute appellation contraire à ces critères dans un délai raisonnable fixé par TecAlliance qui ne peut être inférieur à deux (2) semaines, à l’occasion de la fourniture de données ultérieure. Si le client ne donne pas suite à cette requête de TecAlliance, TecAlliance peut procéder elle-même au retrait des données non conformes au contrat, aux frais du client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Responsabilité pour les données</h1>
                                <ol id="l79">
                                    <li data-list-text="2.10.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client certifie détenir tous les droits de publication et d’utilisation des données fournies. Le client est seul responsable des données fournies.</p>
                                    </li>
                                    <li data-list-text="2.10.3.2.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance se réserve le droit de retirer de la publication les données portant atteinte à la propriété intellectuelle de tiers, les données qui concernent des produits interdits par la loi ou des produits soumis à un embargo ou des restrictions. TecAlliance informe le client de la non-publication / suppression prévue des données en question et lui donne la possibilité de formuler des observations.</p>
                                    <ol id="l80">
                                        <li data-list-text="2.10.3.2.1.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client n’émet pas d’observation correspondante dans un délai raisonnable, TecAlliance est en droit de procéder à la suppression. TecAlliance vérifie ces observations. Ceci ne s&#39;applique pas aux violations manifestes de la loi.</p>
                                        </li>
                                        <li data-list-text="2.10.3.2.2.">
                                            <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">À aucun moment TecAlliance n’est tenue de rechercher</p>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">activement de telles données.</p>
                                        </li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.4.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Mise à jour des données</h1>
                                <ol id="l81">
                                    <li data-list-text="2.10.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met régulièrement à jour les structures de recherche des articles et des données de référence des véhicules TecDoc (« Reference Data ») et les met à la disposition du client dans leur forme actualisée à intervalles réguliers. TecAlliance fournit en outre au client un calendrier annuel des dates de remise et de publication des données, ainsi que de livraison de la Reference Data.</p>
                                    </li>
                                    <li data-list-text="2.10.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de mettre à jour régulièrement et en temps utile les données relatives à ses produits, et de les tenir à jour lors de modifications. Les données actualisées doivent, dans tous les cas, correspondre à la version actuelle en vigueur de la Reference Data.</p>
                                    </li>
                                    <li data-list-text="2.10.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Cette obligation concerne notamment la mise à disposition de TecAlliance de toutes les informations produits pertinentes, telles que les nouveaux articles, les nouveaux usages des véhicules, les modifications de prix, etc. et ce, sans délai, afin que TecAlliance puisse les traiter et les mettre à disposition de l’IAM.</p>
                                    </li>
                                    <li data-list-text="2.10.4.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de fournir et de valider régulièrement les données à l’égard de TecAlliance, au plus tard tous les six (6) mois. À cette fin, le client doit utiliser la / les version(s) actuelle(s) du logiciel aux fins de fourniture des données. TecAlliance informe constamment le client sur la / les version(s) en vigueur dans le logiciel aux fins de fourniture des données.</p>
                                    </li>
                                    <li data-list-text="2.10.4.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client ne satisfait pas à l’obligation qui précède, TecAlliance se réserve le droit de valider, aux frais du client, les données fournies en dernier lieu par le client avec la / les version(s) en vigueur aux fins de fourniture des données.</p>
                                    </li>
                                    <li data-list-text="2.10.4.6.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Lorsque le client fournit des données sur les prix, il indique à TecAlliance si ces prix peuvent être communiqués.</p>
                                    </li>
                                    <li data-list-text="2.10.4.7.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance informe le client des erreurs dont elle a connaissance dans les données livrées. Dans ce cas, le client est tenu de remédier sans délai à l&#39;erreur ou d&#39;aider TecAlliance à remédier à l&#39;erreur dans la base de données.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droits d’exploitation</h1>
                                <ol id="l82">
                                    <li data-list-text="2.10.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client concède à TecAlliance tous les droits nécessaires, et notamment le droit de reproduire et de publier les données mises à disposition par le client.</p>
                                    </li>
                                    <li data-list-text="2.10.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est autorisé à utiliser les données au format TecDoc (données d’articles créées en lien ou en association avec la TecDoc Reference Data) à ses propres fins. L&#39;usage pour son propre compte comprend notamment l&#39;élaboration et la gestion des données, la communication avec les clients du fournisseur de données concernant les listes de produits et de prix, les catalogues internet, les boutiques en ligne, les catalogues imprimés, les newsletters.</p>
                                    </li>
                                    <li data-list-text="2.10.5.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client n’est en outre pas autorisé à transmettre des données au format TecDoc à des tiers, à l’exception d’entreprises liées au groupe au sens des articles 15 et suivants de l’AktG.</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                    <li data-list-text="2.10.5.4.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données peuvent uniquement être transmises à des tiers si TecAlliance a conclu avec eux un contrat de licence valable sur les données concernées. TecAlliance peut uniquement refuser de conclure un tel contrat de licence en présence d&#39;un intérêt manifestement opposé.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.6.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Redevances</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En contrepartie des services fournis par TecAlliance, le client paye des frais pour chaque marque qu&#39;il a intégrée. Ces frais se composent d&#39;une redevance initiale unique et de redevances annuelles récurrentes.</p>
                                <ol id="l83">
                                    <li data-list-text="2.10.6.1.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Redevance initiale</h1>
                                    <ol id="l84">
                                        <li data-list-text="2.10.6.1.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pour chaque marque intégrée par le client, il doit s’acquitter d’une redevance initiale unique dont le montant est déterminé en fonction du chiffre d’affaires réel pour la marque en question, par rapport à l’activité commerciale dans l’IAM Europe et à l’exercice qui a précédé la conclusion du contrat.</p>
                                        </li>
                                        <li data-list-text="2.10.6.1.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque million d’euros de chiffre d’affaires net est multiplié par le taux de la redevance initiale, en supposant un chiffre d’affaires minimum de huit (8) millions d’euros. Les plafonds de chiffre d’affaires indiqués dans la liste de prix se réfèrent à la règle prévue à l’art. 2.10.6.11.</p>
                                        </li>
                                        <li data-list-text="2.10.6.1.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le taux de la redevance initiale est déterminé par la liste des prix en vigueur au moment de la conclusion du contrat.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.10.6.2.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Redevance annuelle</h1>
                                    <ol id="l85">
                                        <li data-list-text="2.10.6.2.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pour chaque marque intégrée par le client, une redevance annuelle est due pour la durée du contrat, dont le montant est déterminé en fonction du chiffre d’affaires réel pour la marque en question, par rapport à l’activité commerciale dans l’IAM Europe et à l’exercice qui précède la facturation.</p>
                                        </li>
                                        <li data-list-text="2.10.6.2.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque million d’euros de chiffre d’affaires net est multiplié par le taux de la redevance annuelle, en supposant un chiffre d’affaires minimum de huit (8) millions d’euros. Les plafonds de chiffre d’affaires indiqués dans la liste de prix se réfèrent à la règle prévue à l’art. 2.10.6.11.</p>
                                        </li>
                                        <li data-list-text="2.10.6.2.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque année, il est procédé à un ajustement au chiffre d&#39;affaires de l&#39;année précédente. À cet effet, le client est tenu de communiquer à TecAlliance les chiffres d’affaires pertinents au plus tard à la fin du mois de février de chaque année. La nouvelle redevance annuelle qui en résulte est valable à partir du 01/04.</p>
                                        </li>
                                        <li data-list-text="2.10.6.2.4.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pour déterminer les chiffres d&#39;affaires pertinents, les sommes inférieures à 500 000 € net sont arrondies au million inférieur et les sommes supérieures ou égales à 500 000 € net sont arrondies au million supérieur.</p>
                                        </li>
                                        <li data-list-text="2.10.6.2.5.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client intègre ultérieurement une marque qui, en raison d’une reprise juridique ou légale de cette marque, ne présente pas encore de chiffre d’affaires de l’année précédente propre au client au moment de l’intégration, le chiffre d’affaires de l’année précédente de cette marque chez le prédécesseur juridique doit être prise comme base pour le calcul de la première redevance annuelle pour cette marque supplémentaire.</p>
                                        </li>
                                        <li data-list-text="2.10.6.2.6.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les droits d’entrée sont calculés sans déduction lors de la conclusion du contrat, les redevances annuelles sont calculées pour la première fois au prorata de la période restante à partir du mois de la conclusion du contrat jusqu’à la fin de l’année.</p>
                                            <ol id="l86">
                                                <ol id="l87">
                                                <ol id="l88">
                                                    <ol id="l89">
                                                        <li data-list-text="2.10.6.7.">
                                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut demander qu&#39;un commissaire aux comptes atteste de l&#39;exactitude des chiffres d&#39;affaires. Si un tel examen fait apparaître des écarts à la charge de TecAlliance qui dépassent 5 %, le client prend en charge les frais d’examen nécessaires. Toute divergence supérieure à 10 % constitue une violation grave du contrat pouvant entraîner une rupture du contrat sans préavis. Cette</p>
                                                            <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">autorisation s’applique également si l’examen n’est pas possible faute</p>
                                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">de documents auditables.</p>
                                                        </li>
                                                        <li data-list-text="2.10.6.8.">
                                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les règles sur la répartition des coûts ou le droit de résiliation extraordinaire ne s’applique que si les écarts ont des conséquences à la charge de TecAlliance en ce qui concerne les frais à payer par le client. Par ailleurs, TecAlliance prend en charge les frais d’examen.</p>
                                                        </li>
                                                        <li data-list-text="2.10.6.9.">
                                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si les informations relatives aux chiffres d’affaires ne sont pas communiquées en temps utile, TecAlliance peut rompre le contrat sans préavis. TecAlliance garantit  que les  chiffres d’affaires communiqués par le client sont traités de manière confidentielle.</p>
                                                        </li>
                                                        <li data-list-text="2.10.6.10.">
                                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de notifier immédiatement à TecAlliance le transfert des droits d’utilisation des marques intégrées par le client. Si le droit d’usage sur la marque inte´grée est transmis à une entreprise liée au sens de l’art. 15 de la loi sur les sociétés anonymes, la facturation reste la même. Si le droit d’utilisation du client sur une marque intégrée disparaît avec le transfert du droit d’utilisation et si le droit d’utilisation n’est pas non plus transféré à une entreprise liée au client au sens de l’article 15 de la loi sur les sociétés anonymes, cette marque ne sera pas facturée.</p>
                                                        </li>
                                                    </ol>
                                                </ol>
                                                </ol>
                                            </ol>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.10.6.3.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Redevance maximales</h1>
                                    <ol id="l90">
                                        <li data-list-text="2.10.6.3.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les clients possédant plusieurs marques ne recevront pas de facture distincte pour chaque marque, mais une facture groupée jusqu’à un chiffre d’affaires maximal de 60 millions Ainsi, chaque marque est enregistrée pour un chiffre d’affaires réel &lt; 8 millions d’euros avec le chiffre d’affaires minimum de 8 millions d’euros. Il n’y a plus de calcul des taxes annuelles lorsque le chiffre d’affaires commercial cumulé dans l’IAM pour toutes les marques enregistrées dans TecDoc a atteint le plafond de 60 millions d’euros.</p>
                                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Pour cela, il faut un cocontractant unique pour toutes les marques et</p>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">une facturation centralisée à l’adresse proposée par le client. 2.10.6.3.2.  La règle prévue à l’art. 2.10.6.11.1 ne s’applique pas en cas de fusion ou de rachat de deux ou plusieurs entreprises qui sont déjà fournisseurs de données. En cas de fusion ou de rachat entre fournisseurs de données, les règles suivantes relatives au calcul des redevances s’appliquent : si les marques intégrées restent inchangées avant et après la fusion ou un rachat, à savoir les marques enregistrées comme marques TecDoc, le montant des redevances reste le même. 2.10.6.3.3.  Lors de l’ajout d’une nouvelle marque, des frais de licence uniques sont facturés pour l’examen et la saisie supplémentaires qui s’imposent. Cette règle s’applique uniquement aux contrats dépassant un chiffre d’affaires maximal de 60 millions d’euros. Il n’y a pas de redevances annuelles supplémentaires, à condition que le client ne dépasse pas le nombre de 15 marques avec cette marque. À partir de la 16<span class="s13">e </span>marque, une redevance annuelle forfaitaire supplémentaire est facturée par marque afin de compenser le coût du processus de production mensuel et les dépenses supplémentaires. Ces redevances sont également ajustées en cas d&#39;augmentation générale des redevances.</p>
                                        </li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.7.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: left;">Durée du contrat et résiliation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;"><a href="#bookmark5" class="a">Par dérogation à l’article </a>1.8.3 des présentes CGV, la durée minimale du contrat est de trois (3) ans et le délai de préavis est de six (6) mois.</p>
                            </li>
                            <li data-list-text="2.10.8.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Responsabilité</h1>
                                <ol id="l91">
                                    <li data-list-text="2.10.8.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance ne saurait garantir la pertinence, l’exhaustivité, l’exactitude des contenus et l’absence d’erreurs des données. Ainsi, TecAlliance ne saurait répondre de la perte ou de la détérioration fortuites reposant sur l’inexactitude ou le contenu incomplet des données mises à disposition par le client ou sur un défaut d’actualisation des données.</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                    <li data-list-text="2.10.8.2.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance décline toute responsabilité et ne répond pas du contenu des données et informations mises à disposition par le client. TecAlliance n&#39;est pas tenue d&#39;examiner la licéité des données et informations fournies.</p>
                                    </li>
                                    <li data-list-text="2.10.8.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si les données et informations mises à disposition par le client sont contraires au droit, il garantit TecAlliance contre toute action en résultant et assume les coûts en résultant. Ces coûts comprennent également les frais de défense en justice.</p>
                                    </li>
                                    <li data-list-text="2.10.8.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance répond entièrement de la structure de l&#39;arborescence de recherche TecDoc, de la Reference Data, ainsi que des données maîtresses de TecDoc et garantit le client contre toute responsabilité au regard des données mentionnées. Ceci s&#39;applique notamment à l&#39;utilisation des données mentionnées dans le cadre de la gestion des données, conformément aux dispositions du présent contrat.</p>
                                    </li>
                                    <li data-list-text="2.10.8.5.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">La responsabilité du client pour les données qu’il met à</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">disposition est limitée à la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.10.8.6.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client ne répond pas des modifications et/ou des adaptations en aval de la structure de l’arborescence de recherche TecDoc, de la Reference Data, ainsi que des données maîtresses TecDoc par TecAlliance et/ou d’autres clients.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.10.9.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Marketing</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les parties peuvent faire de la publicité avec leur coopération mutuelle. Toutefois, toutes les campagnes publicitaires doivent être validées au préalable sous forme littérale par l’autre partie. En cas d’utilisation du sigle « TecAlliance Data Supplier », le client est tenu d’utiliser la version la plus récente du sigle.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.11.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">PartCat Online, TecDoc DMM Online, TecDoc ONE</p>
                        <ol id="l92">
                            <li data-list-text="2.11.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l93">
                                    <li data-list-text="2.11.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenu de fournir au client un accès au logiciel PartCat Online/TecDoc DMM Online/TecDoc ONE pendant la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.11.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">PartCat Online/TecDoc DMM Online/TecDoc ONE est mis à disposition à titre de SaaS (Software as a Service).</p>
                                    </li>
                                    <li data-list-text="2.11.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le matériel informatique et les logiciels nécessaires à l’utilisation de PartCat Online/TecDoc DMM Online/TecDoc ONE doivent être fournis par le client. Le client doit les configurer et les installer lui- même.</p>
                                    </li>
                                    <li data-list-text="2.11.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut modifier PartCat Online/TecDoc DMM Online/TecDoc ONE et en remettre une mise à jour au client. TecAlliance annonce dans un délai raisonnable les mises à jour de nature à perturber l’implémentation du client. Le client est tenu de tenir compte de ces mises à jour dans son implémentation dès leur réception.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.11.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l94">
                                    <li data-list-text="2.11.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client le droit non exclusif, non sous-licenciable, limité à la durée du contrat d’utiliser PartCat Online/TecDoc DMM Online/TecDoc ONE aux fins de transfert des données à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.11.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L&#39;utilisation autorisée comprend l’usage conforme au contrat de PartCat Online/TecDoc DMM Online/TecDoc ONE par le client, à savoir la transmission des modifications, suppressions et actualisations des données des articles du client.</p>
                                    </li>
                                    <li data-list-text="2.11.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">PartCat Online/TecDoc DMM Online/TecDoc ONE peut uniquement être utilisé pour transmettre les données des marques pour lesquelles il existe un contrat de fourniture de données en cours de validité et pour lesquelles TecAlliance a attribué une autorisation d’accès ou des données d’accès correspondant à l’accord contractuel.</p>
                                    </li>
                                    <li data-list-text="2.11.2.4.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">La mise à disposition des données d’accès et/ou de</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">PartCat Online/TecDoc DMM Online/TecDoc ONE à des tiers (par ex.</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">prestataires de services) est possible uniquement après validation par TecAlliance sous forme de texte.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.11.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Maintenance et traitement des erreurs</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance garantit le maintien des propriétés contractuelles de PartCat Online/TecDoc DMM Online/TecDoc ONE (cf. descriptif des prestations) pendant la durée du contrat et garantit qu’aucun droit de tiers ne s’oppose à une utilisation conforme de PartCat Online/TecDoc DMM Online/TecDoc ONE. TecAlliance élimine toute défaillance de PartCat Online/TecDoc DMM Online/TecDoc ONE en temps utile (mises à jour).</p>
                            </li>
                            <li data-list-text="2.11.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Adaptation des prix TecDoc ONE</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’évolution des prix de TecDoc ONE est liée à l’évolution du prix de la Data Supplier License délivrée au client. En cas de modification du prix de la Data Supplier License, le prix de TecDoc ONE est ajusté à concurrence de 1/3 (un tiers) du nouveau prix de la Data Supplier License, sans notification particulière en ce sens.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.12.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">IDP API Data Supplier</p>
                        <ol id="l95">
                            <li data-list-text="2.12.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l96">
                                    <li data-list-text="2.12.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenue de fournir au client l’interface IDP pour les fournisseurs de données (IDP API Data Supplier) pendant la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.12.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">IDP API Data Supplier est mise à disposition à titre de SaaS (Software as a Service). Au terme de l’implémentation effectuée par le client, TecAlliance met à la disposition du client, pour chaque marque convenue, les données d’accès nécessaires à la mise en service de l’IDP API Data Supplier.</p>
                                    </li>
                                    <li data-list-text="2.12.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit fournir le matériel informatique et les logiciels nécessaires à l’utilisation de l’IDP API Data Supplier. Le client doit les configurer et les installer lui-même.</p>
                                    </li>
                                    <li data-list-text="2.12.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut modifier l’IDP API Data Supplier et en remettre une mise à jour au client. TecAlliance annonce dans un délai raisonnable les mises à jour de nature à perturber l’implémentation du client. Le client est tenu d’inclure les mises à jour dans son implémentation immédiatement après les avoir reçues.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.12.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Redevances et environnement de test</h1>
                                <ol id="l97">
                                    <li data-list-text="2.12.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Pour les prestations de services fournies par TecAlliance, le client doit verser une redevance annuelle.</p>
                                    </li>
                                    <li data-list-text="2.12.2.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance donne accès au client à un environnement de test pour l&#39;IDP API Data Supplier dans un délai de 10 jours ouvrables. Celui-ci est valable jusqu’au changement de l’environnement productif. TecAlliance décline toute responsabilité pour le fonctionnement de l’environnement de test.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.12.3.">
                                <h1 style="padding-top: 1pt;padding-left: 40pt;text-indent: -35pt;text-align: justify;">Droits d’exploitation</h1>
                                <ol id="l98">
                                    <li data-list-text="2.12.3.1.">
                                    <p style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client un droit non exclusif, non sous-licenciable, limité à la durée du contrat d’utiliser l’IDP API Data Supplier à des fins de transfert de données à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.12.3.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’utilisation autorisée comprend l’usage conforme de l’IDP API Data Supplier par le client, à savoir la transmission des modifications, suppressions et actualisations des données des articles du client.</p>
                                    </li>
                                    <li data-list-text="2.12.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’IDP API Data Supplier peut uniquement être utilisée pour transmettre les données des marques pour lesquelles il existe un contrat de fourniture de données TecDoc en cours de validité et pour lesquelles TecAlliance a attribué une autorisation d’accès ou des données d’accès correspondant à l’accord contractuel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.12.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Maintenance et traitement des erreurs</h1>
                                <ol id="l99">
                                    <li data-list-text="2.12.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">TecAlliance garantit le maintien de la qualité de l&#39; IDP API Data Supplier (voir description des prestations) convenue par contrat</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">pendant la durée du contrat, et s&#39;assure que l’utilisation contractuelle de l&#39; IDP API Data Supplier n’est pas en conflit avec les droits des tiers. TecAlliance remédie à toute défaillance de l’IDP API Data Supplier en temps utile (mises à jour).</p>
                                    </li>
                                    <li data-list-text="2.12.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’informer TecAlliance de toute défaillance de l’IDP API Data Supplier sous forme de texte immédiatement après l’avoir constatée.</p>
                                    </li>
                                    <li data-list-text="2.12.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenue d&#39;examiner les erreurs de l&#39;IDP API Data Supplier notifiées par le client sous forme écrite lorsque les conditions suivantes sont remplies : 1. L&#39;erreur est reproductible ; 2. L&#39;erreur apparaît dans la dernière version de l&#39;IDP API Data Supplier et le client a lui-même implémenté cette version ; 3. Le client fournit à TecAlliance toutes les informations sur les circonstances de l&#39;apparition des erreurs.</p>
                                    </li>
                                    <li data-list-text="2.12.4.4.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Après avoir examiné l’erreur, TecAlliance décidera, à sa</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">seule discrétion</p>
                                    <ol id="l100">
                                        <li data-list-text="2.12.4.4.1.">
                                            <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">de résoudre l’erreur et / ou</p>
                                        </li>
                                        <li data-list-text="2.12.4.4.2.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">de proposer un dépannage préliminaire si cela est nécessaire pour des raisons urgentes ou si le dépannage est techniquement impossible ou n’est pas justifiable d’un point de vue économique et / ou</p>
                                        </li>
                                        <li data-list-text="2.12.4.4.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">d’informer le client sur la raison pour laquelle l’erreur signalée n’est pas éligible à un dépannage ou un dépannage préliminaire.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.12.4.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance remet au client des mises à jour de l&#39;IDP API Data Supplier dans le cadre de la maintenance et du développement. Le client est tenu d’inclure les mises à jour dans son implémentation immédiatement après les avoir reçues.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.12.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Documentation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client reçoit une description de l’interface dans le cadre du projet de conseil sur l’onboarding. Elle est fournie au client pour son usage interne.</p>
                            </li>
                            <li data-list-text="2.12.6.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Support technique</h1>
                                <ol id="l101">
                                    <li data-list-text="2.12.6.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le support technique est fourni par TecAlliance ou un tiers mandaté par TecAlliance. Il s’articule autour des catégories suivantes dans le fonctionnement productif :</p>
                                    <ol id="l102">
                                        <li data-list-text="2.12.6.1.1.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Support de premier niveau (dépannage par un Customer Help Desk ; conseil sur des questions relatives à l’IDP API Data Supplier – erreurs connues) et</p>
                                        </li>
                                        <li data-list-text="2.12.6.1.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Support technique de deuxième niveau (prise en charge et diagnostic des messages de problèmes du support technique de premier niveau. Correction de l’erreur et fourniture d’une solution de contournement ou de correction).</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.12.6.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">En mode test, le support technique est fourni dans le cadre</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">du projet de conseil sur l’onboarding.</p>
                                    </li>
                                    <li data-list-text="2.12.6.3.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toutes les prestations de support technique peuvent être fournies par TecAlliance ou un tiers mandaté par TecAlliance, par téléphone ou sous forme de service à distance. Si un service à distance n’est pas approuvé par le client et qu’un support technique sur site est nécessaire, le client doit en supporter les coûts et les dépenses.</p>
                                    </li>
                                    <li data-list-text="2.12.6.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si des problèmes particuliers nécessitent une intervention supplémentaire, TecAlliance fournira un support technique sur site moyennant des frais.</p>
                                    </li>
                                    <li data-list-text="2.12.6.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le support technique est joignable du lundi au vendredi de 8h00 à 18h00 CET. Aucun support technique n’est fourni les jours suivants : 01/01, 25/12, 26/12. Un service de garde est disponible exclusivement pour les cas très critiques. Les demandes de renseignements par e-mail sont disponibles ces jours-là de 8h00 à 18h00. Le week-end et les jours fériés, un service de garde est</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">disponible exclusivement pour les cas très critiques pour les demandes de renseignements par e-mail du lundi au vendredi de 18h00 à 20h00 et les samedis et dimanches de 10h00 à 18h00.</p>
                                    </li>
                                    <li data-list-text="2.12.6.6.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Le support technique est disponible au moins en anglais.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.13.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">DMM</p>
                        <ol id="l103">
                            <li data-list-text="2.13.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l104">
                                    <li data-list-text="2.13.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">TecAlliance est tenu de fournir au client le logiciel DMM pendant la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.13.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le logiciel est mis à disposition du client par téléchargement.</p>
                                    </li>
                                    <li data-list-text="2.13.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel comprend la fonctionnalité nécessaire à la saisie de données ou d’articles et à leur affectation aux véhicules TecDoc. Le client doit lui-même tenir les données à jour après la mise à disposition du logiciel, puis les transmettre à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.13.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit fournir le matériel et les logiciels nécessaires au fonctionnement du logiciel. Le client doit configurer et installer lui- même le logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.13.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l105">
                                    <li data-list-text="2.13.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client un droit non exclusif d’utiliser le logiciel à des fins de mise à jour des données, limité dans le temps à la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.13.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’utilisation autorisée comprend l’installation du logiciel, le téléchargement sur le disque dur, ainsi que l’usage conforme par le client.</p>
                                    </li>
                                    <li data-list-text="2.13.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel peut uniquement être utilisé par le client pour tenir à jour les données des marques pour lesquels il possède un contrat de fournisseur de données en cours de validité avec TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.13.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">En aucun cas, le client n’a le droit de louer le logiciel ou d’accorder une sous-licence, de le rendre public ou accessible au public, ou de le mettre à la disposition de tiers.</p>
                                    </li>
                                    <li data-list-text="2.13.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La mise à disposition du logiciel par le client à des tiers aux fins de mise à jour externe des données est interdite. Il peut uniquement être transmis à des tiers dans des cas exceptionnels justifiés, avec l’accord préalable sous forme littérale de TecAlliance.</p>
                                    <ol id="l106">
                                        <li data-list-text="2.13.2.5.1.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans ce cas, le client doit engager le tiers, sous forme littérale, à utiliser le logiciel exclusivement en conformité avec les réglementations du présent contrat.</p>
                                        </li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.13.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Maintenance</h1>
                                <ol id="l107">
                                    <li data-list-text="2.13.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance garantit le maintien de la qualité du logiciel convenue par contrat pendant la durée du contrat, et assure que l’utilisation contractuelle du logiciel n’est pas en conflit avec les droits des tiers. TecAlliance remédiera à tout défaut du logiciel en temps voulu.</p>
                                    </li>
                                    <li data-list-text="2.13.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’informer TecAlliance de tout défaut du logiciel sous forme littérale immédiatement après l’avoir constaté. Pour les défauts matériels, il convient de décrire le moment de l’apparition des défauts et les circonstances plus spécifiques.</p>
                                    </li>
                                    <li data-list-text="2.13.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance fournit des mises à jour au client dans le cadre de la maintenance. Le client est tenu d’installer les mises à jour immédiatement, mais au plus tard quatre (4) semaines après leur réception, et de cesser d’utiliser des versions obsolètes du logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.13.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Documentation, formation</h1>
                                <ol id="l108">
                                    <li data-list-text="2.13.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance propose une journée de formation gratuite à l’utilisation du logiciel pour les employés du client par webinaire ou sur le site de TecAlliance à Cologne.</p>
                                    </li>
                                    <li data-list-text="2.13.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les instructions d’installation et de configuration ne font pas partie du contrat, mais peuvent être convenues séparément entre les parties.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.13.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Support technique</h1>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                <ol id="l109">
                                    <li data-list-text="2.13.5.1.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Le support technique est fourni par TecAlliance ou un tiers mandaté par TecAlliance. Il s’articule autour des catégories suivantes : 2.13.5.1.1.  Support  technique  de  premier  niveau  (dépannage téléphonique par un Customer Help Desk – CHD ; conseil sur des questions relatives à l’application – utilisation, erreurs connues) et 2.13.5.1.2.  Support technique de deuxième niveau (prise en charge et diagnostic des messages de problèmes du support technique de premier niveau. Correction de l’erreur et fourniture d’une solution de contournement ou de correction).</p>
                                    </li>
                                    <li data-list-text="2.13.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toutes les prestations de support technique (exception : soutien à la formation) peuvent être fournies par TecAlliance ou un tiers mandaté par TecAlliance, par téléphone ou sous forme de service à distance. Si un service à distance n’est pas approuvé par le client et qu’un support sur site est nécessaire, le client doit en supporter les coûts et les dépenses.</p>
                                    </li>
                                    <li data-list-text="2.13.5.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si des problèmes particuliers nécessitent une intervention supplémentaire, TecAlliance fournira un support technique sur site moyennant des frais.</p>
                                    </li>
                                    <li data-list-text="2.13.5.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La disponibilité du support technique est calquée sur les heures de bureau habituelles (du lundi au vendredi de 9h00 à 17h00 CET). Aucun support technique ne sera assuré pendant les jours fériés dans la région de Rhénanie-du-Nord-Westphalie.</p>
                                    </li>
                                    <li data-list-text="2.13.5.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le support technique est disponible en allemand et en anglais.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.14.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">CCU</p>
                        <ol id="l110">
                            <li data-list-text="2.14.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l111">
                                    <li data-list-text="2.14.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">TecAlliance est tenu de fournir au client le logiciel CCU pendant la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.14.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance fournit au client le logiciel exécutable dans les huit (8) semaines suivant la conclusion du contrat et la livraison des données du client.</p>
                                    </li>
                                    <li data-list-text="2.14.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le logiciel est mis à disposition du client par téléchargement.</p>
                                    </li>
                                    <li data-list-text="2.14.1.4.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Le client est autorisé à installer le logiciel sur ses appareils.</p>
                                    </li>
                                    <li data-list-text="2.14.1.5.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance garantit que le logiciel est exempt de droits de tiers et, en particulier, ne viole aucun brevet, droit d’auteur ou autre droit de propriété intellectuelle de tiers.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.14.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l112">
                                    <li data-list-text="2.14.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client un droit non exclusif, non transférable et non susceptible de sous-licence d’utiliser le logiciel conformément aux dispositions des présentes conditions générales, limité dans le temps à la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.14.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pendant la durée du contrat, le client est autorisé à copier tout ou partie du logiciel sous une forme écrite et / ou lisible par une machine afin de l’installer et / ou de l’exécuter.</p>
                                    </li>
                                    <li data-list-text="2.14.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Pendant la durée du contrat, le client est autorisé à</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">effectuer une copie du logiciel à des fins de sauvegarde et d’archivage.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.14.3.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Documentation, formation et assistance</h1>
                                <ol id="l113">
                                    <li data-list-text="2.14.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les manuels et la documentation du logiciel sont mis à disposition exclusivement pour une utilisation du client en interne.</p>
                                    </li>
                                    <li data-list-text="2.14.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenu de former les employés de ses clients à l’utilisation du logiciel. La formation comprend deux jours de formation pour un maximum de dix (10) personnes sur le site TecAlliance aux Pays-Bas.</p>
                                    </li>
                                    <li data-list-text="2.14.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="mailto:support.MST@tecalliance.net" class="a" target="_blank">TecAlliance fournit un support pour le logiciel aux jours suivants : du lundi au vendredi de 8h00 à 16h30 (sauf jours fériés néerlandais). Vous pouvez contacter notre assistance au numéro +31 43 308 86 81 ou à l’adresse </a>support.MST@tecalliance.net. L’assistance est fournie en anglais et en néerlandais.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.14.4.">
                                <h1 style="padding-top: 4pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Gestion des erreurs</h1>
                                <ol id="l114">
                                    <li data-list-text="2.14.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenue d&#39;examiner les erreurs du logiciel notifiées par le client sous forme écrite lorsque les conditions suivantes sont remplies : 1. L&#39;erreur est reproductible ; 2. L&#39;erreur apparaît dans la dernière version du logiciel et le client à lui-même installé cette version ;</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">3. Le client fournit à TecAlliance toutes les informations sur les circonstances de l&#39;apparition des erreurs ; 4. Le client ou un tiers n&#39;a apporté aucune modification au logiciel, sauf si TecAlliance y a consenti.</p>
                                    </li>
                                    <li data-list-text="2.14.4.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Après avoir examiné l’erreur, TecAlliance décidera, à sa</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">seule discrétion</p>
                                    <ol id="l115">
                                        <li data-list-text="2.14.4.2.1.">
                                            <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">de résoudre l’erreur et / ou</p>
                                        </li>
                                        <li data-list-text="2.14.4.2.2.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">de proposer un dépannage préliminaire si cela est nécessaire pour des raisons urgentes ou si le dépannage est techniquement impossible ou n’est pas justifiable d’un point de vue économique et / ou</p>
                                        </li>
                                        <li data-list-text="2.14.4.2.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">d’informer le client sur la raison pour laquelle l’erreur signalée n’est pas éligible à un dépannage ou un dépannage préliminaire.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="2.14.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance s’efforce de collaborer avec le client pour résoudre les erreurs. Toutefois, les réparations et / ou les temps de maintenance supplémentaires occasionnés par l’utilisation abusive ou non autorisée du logiciel par le client sont à sa charge. Si cela est nécessaire et possible, TecAlliance fournira au client une estimation préalable des coûts.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.14.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Conséquence de la cessation du contrat</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de cessation du présent contrat, et quelle qu’en soit la raison, le client doit cesser, immédiatement et entièrement, toute exploitation du logiciel, supprimer toutes les copies existant dans ses systèmes et effacer ou remettre à TecDoc les éventuelles copies de sauvegarde.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.15.">
                        <h1 style="padding-left: 23pt;text-indent: -17pt;text-align: left;">TecDoc DataWave</h1>
                        <ol id="l116">
                            <li data-list-text="2.15.1.">
                                <h1 style="padding-top: 1pt;padding-left: 29pt;text-indent: -23pt;text-align: left;">Contenu de la prestation de services</h1>
                                <ol id="l117">
                                    <li data-list-text="2.15.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">TecAlliance met à disposition une plateforme de téléchargement sur laquelle il est</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">possible d’envoyer des paquets complets de données formatées par TecDoc (TAF) qui seront publiées sur les canaux de distribution TecDoc. 2.15.1.2.TecDoc Data Wave est fournie en tant que Software as a Service (SaaS). TecAlliance remet au client les données d’accès nécessaires au téléchargement des paquets de données TecDoc.</p>
                                    <ol id="l118">
                                        <ol id="l119">
                                            <ol id="l120">
                                                <ol id="l121">
                                                <li data-list-text="2.15.2.3.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le matériel et les logiciels informatiques nécessaires au client pour utiliser TecDoc Data Wave doivent être fournis par le client. Le client doit les configurer et les installer lui-même.</p>
                                                </li>
                                                <li data-list-text="2.15.2.4.">
                                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut modifier la TecDoc Data Wave et la proposer au client sous forme de mise à jour. TecAlliance annonce dans un délai préalable raisonnable les mises à jour susceptibles de perturber l’implémentation du client. Le client est tenu d’implémenter les mises à jour immédiatement après les avoir reçues.</p>
                                                </li>
                                                </ol>
                                            </ol>
                                        </ol>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.15.2.">
                                <h1 style="padding-left: 45pt;text-indent: -39pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l122">
                                    <li data-list-text="2.15.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client un droit non exclusif, non sous- licenciable et limité à la durée du présent contrat d’utiliser la TecDoc Data Wave à des fins de transfert de données à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.15.2.2.">
                                    <p style="padding-left: 6pt;text-indent: -1pt;line-height: 118%;text-align: justify;">Le client est tenu de télécharger des paquets de données définis au</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">format TecDoc avec des informations produits actuelles conformément au standard TecDoc.</p>
                                    </li>
                                    <li data-list-text="2.15.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L&#39;utilisation de la TecDoc Data Wave aux fins de transfert de données pour des marques est uniquement possible s’il existe un contrat de fourniture de données TecDoc en cours de validité et si</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">TecAlliance a attribué une autorisation d’accès ou des données d’accès</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">conformément aux conditions actuelles.</p>
                                    </li>
                                    <li data-list-text="2.15.2.4.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Avant publication, le client peut télécharger le paquet de données aux fins de validation des données. L’utilisation de TecDoc Data Wave exclusivement aux fins de validation des données constitue une violation substantielle du contrat autorisant TecAlliance à résilier le contrat TecDoc Data Wave sans préavis, conformément à l’article 1.8.</p>
                                    </li>
                                    <li data-list-text="2.15.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La prestation de services se base sur le traitement des paquets TAF formatés conformément à la documentation remise au client. Il revient au client de s’assurer du respect de cette règle. TecAlliance se réserve le droit de facturer au client tous les frais résultant des échecs de téléchargement dus aux données non conformes téléchargées par le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.15.3.">
                                <h1 style="padding-left: 41pt;text-indent: -35pt;text-align: justify;">Onboarding et redevances</h1>
                                <ol id="l123">
                                    <li data-list-text="2.15.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Le client doit verser une redevance pour les prestations fournies par TecAlliance. La redevance comprend une redevance initiale unique et une redevance pour chaque paquet de données publié. La deuxième procédure de validation est gratuite en cas de publication d’un paquet de données. La troisième procédure de validation et toute autre procédure de validation sans publication seront considérées comme des publications payantes.</p>
                                    </li>
                                    <li data-list-text="2.15.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à la disposition du client un environnement de test pour TecDoc Data Wave dans un délai de dix (10) jours ouvrables à compter de la conclusion du contrat. Il en va de même jusqu’à la migration dans l’environnement de production. TecAlliance ne répond pas du fonctionnement de l’environnement de test.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.15.4.">
                                <h1 style="padding-left: 41pt;text-indent: -35pt;text-align: justify;">Maintenance et traitement des erreurs</h1>
                                <ol id="l124">
                                    <li data-list-text="2.15.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">TecAlliance garantit le respect de la qualité</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">de TecDoc Data Wave prévue au contrat pendant toute la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="2.15.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le client est tenu de signaler à TecAlliance par écrit tout défaut affectant TecDoc Data Wave dès sa découverte.</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance est tenu d’examiner les erreurs affectant le TecDoc Data Wave signalées sous forme de texte par le client si les conditions suivantes sont remplies :</p>
                                    <ol id="l125">
                                        <li data-list-text="1">
                                            <p style="padding-left: 11pt;text-indent: -5pt;text-align: left;">L’erreur est reproductible ;</p>
                                        </li>
                                        <li data-list-text="2">
                                            <p style="padding-top: 1pt;padding-left: 11pt;text-indent: -5pt;text-align: left;">L’erreur se produit dans la dernière version de la TecDoc Data Wave et</p>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">le client a implémenté cette version ;</p>
                                        </li>
                                        <li data-list-text="3">
                                            <p style="padding-top: 1pt;padding-left: 13pt;text-indent: -8pt;text-align: left;">Le client fournit à TecAlliance toutes les informations sur les</p>
                                        </li>
                                    </ol>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">circonstances dans lesquelles l’erreur s’est produite.</p>
                                    </li>
                                    <li data-list-text="2.15.4.3.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre de la maintenance et du développement, TecAlliance remet au client des mises à jour de TecDoc Data Wave. Le client est tenu d’implémenter les mises à jour dès leur réception.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.15.5.">
                                <h1 style="padding-left: 41pt;text-indent: -35pt;text-align: justify;">Documentation</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le client reçoit une documentation utilisateur. Celle-ci lui est exclusivement remise pour son usage interne.</p>
                            </li>
                            <li data-list-text="2.15.6.">
                                <h1 style="padding-left: 41pt;text-indent: -35pt;line-height: 8pt;text-align: justify;">Assistance technique</h1>
                                <ol id="l126">
                                    <li data-list-text="2.15.6.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toutes les prestations d’assistance peuvent être fournies par TecAlliance ou par un tiers mandaté par TecAlliance, par téléphone ou à distance. Si le client ne consent pas à un service à distance et qu’une assistance technique sur site s’impose, les coûts et dépenses en résultant sont à la charge du client. Si des problèmes concrets requièrent d’autres interventions, TecAlliance propose un service sur site payant.</p>
                                    </li>
                                    <li data-list-text="2.15.6.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si  des  problèmes  particuliers  nécessitent  d’autres interventions, TecAlliance fournit une assistance technique sur site payante. L’assistance technique est joignable du lundi au vendredi de 8h00 à 18h00 CET. Aucune assistance technique n’est assurée les jours suivants : 01/01, 25/12, 26/12. Un service de garde est disponible</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">exclusivement pour les cas très critiques. Les demandes de renseignements par e-mail sont possibles ces jours-là de 8h00 à 18h00. Le week-end et les jours fériés, un service de garde est disponible exclusivement pour les cas très critiques pour les demandes de renseignements par e-mail du lundi au vendredi de 18h00 à 20h00 et les samedis et dimanches de 10h00 à 18h00.</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">L’assistance technique est fournie en anglais.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.16.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">myITG</p>
                        <ol id="l127">
                            <li data-list-text="2.16.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l128">
                                    <li data-list-text="2.16.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à disposition du client l’exploitation du logiciel par un accès à distance par internet (Software-as-a-Service, SaaS).</p>
                                    </li>
                                    <li data-list-text="2.16.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel est constamment développé et amélioré. Dans le cadre de ce développement, des fonctions partielles peuvent être modifiées ou supprimées, dans la mesure où ceci ne nuit pas à la bonne exécution du contrat pour le client.</p>
                                    </li>
                                    <li data-list-text="2.16.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données contenues dans le logiciel proviennent d&#39;autres clients ou sont recherchées par TecAlliance avec la diligence requise selon les circonstances. L&#39;évaluation de l&#39;exactitude, de l&#39;actualité et de l&#39;exhaustivité des données incombe exclusivement au client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Modèles de licence</h1>
                                <ol id="l129">
                                    <li data-list-text="2.16.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le logiciel est proposé dans différentes variantes qui se distinguent par leur fonctionnalités.</p>
                                    </li>
                                    <li data-list-text="2.16.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark11">&zwnj;</a>L&#39;utilisation de la variante gratuite (Basic) implique que le client soit inscrit sur le site Internet de TecAlliance en tant que fournisseur de données TecDoc pour au moins une de ses marques.</p>
                                    </li>
                                    <li data-list-text="2.16.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;"><a href="#bookmark11" class="a">Les clients qui ne remplissent pas les conditions prévues à l’article </a>2.16.2.2 peuvent uniquement utiliser la version payante (Plus &amp; Pro) du logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Droits d’exploitation du client</h1>
                                <ol id="l130">
                                    <li data-list-text="2.16.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel et les bases de données incluses sont protégés par le droit d’auteur. Le droit d’auteur, le droit des brevets, le droit des marques et tous les droits voisins sur le logiciel et les bases de données incluses sont la propriété exclusive de TecAlliance. Dans la mesure où les droits reviennent à des tiers, TecAlliance détient les droits d’exploitation correspondants.</p>
                                    </li>
                                    <li data-list-text="2.16.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure prévue par le présent contrat, TecAlliance accorde au client le droit simple, limité à la durée du présent contrat, non transmissible et non sous-licenciable, d’utiliser le logiciel et les bases de données incluses dans le cadre des fonctionnalités du logiciel. Des droits plus étendus ne sont pas accordés au client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l131">
                                    <li data-list-text="2.16.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Une composante essentielle du logiciel est l&#39;exactitude, la mise à jour et l&#39;exhaustivité des données qu&#39;il contient. Le client est donc tenu de toujours tenir ses données à jour dans le logiciel et de les corriger si nécessaire.</p>
                                    </li>
                                    <li data-list-text="2.16.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client s&#39;engage à ne fournir et publier que des données qui ne violent pas les dispositions légales et/ou les droits (y compris les droits de propriété intellectuelle) de tiers.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Transmission des droits d’exploitation</h1>
                                <ol id="l132">
                                    <li data-list-text="2.16.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le client est le propriétaire des données qu&#39;il saisit dans le logiciel et/ou y publie.</p>
                                    </li>
                                    <li data-list-text="2.16.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark2" class="a">Le client accorde à TecAlliance et à ses sociétés affiliées telles que définies dans la clause </a>1.2.2 le droit simple, limité dans le temps à la durée du présent contrat, mondial, cessible et pouvant faire l’objet d’une sous-licence, de traiter, reproduire, diffuser et rendre publiquement disponibles les données que le client saisit dans le logiciel</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">et/ou y publie. Le droit d&#39;exploitation est limité aux fonctionnalités du logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.6.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Adaptations spécifiques au client (COP)</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les adaptations du logiciel (COP) spécifiques au client ne font pas partie de ce contrat. Les demandes correspondantes sont transmises par TecAlliance à un prestataire de services. La conclusion du contrat concernant le COP a lieu exclusivement entre le prestataire de services et le client.</p>
                            </li>
                            <li data-list-text="2.16.7.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: left;">Support technique</h1>
                                <ol id="l133">
                                    <li data-list-text="2.16.7.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Le support technique est fourni par TecAlliance ou un tiers mandaté par TecAlliance. Il s’articule autour des catégories suivantes : 2.16.7.1.1.  Support  technique  de  premier  niveau  (dépannage téléphonique par un Customer Help Desk – CHD ; conseil sur des questions relatives à l’application – utilisation, erreurs connues) et 2.16.7.1.2.  Support technique de deuxième niveau (prise en charge et diagnostic des messages de problèmes du support technique de premier niveau. Correction de l’erreur et fourniture d’une solution de contournement ou de correction).</p>
                                    </li>
                                    <li data-list-text="2.16.7.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toutes les prestations de support technique (exception : soutien à la formation) peuvent être fournies par TecAlliance ou un tiers mandaté par TecAlliance, par téléphone ou sous forme de service à distance. Si un service à distance n’est pas approuvé par le client et qu’un support sur site est nécessaire, le client doit en supporter les coûts et les dépenses.</p>
                                    </li>
                                    <li data-list-text="2.16.7.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si des problèmes particuliers nécessitent une intervention supplémentaire, TecAlliance fournira un support technique sur site moyennant des frais.</p>
                                    </li>
                                    <li data-list-text="2.16.7.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La disponibilité du support technique est calquée sur les heures de bureau habituelles (du lundi au vendredi de 9h00 à 17h00 CET). Aucun support technique ne sera assuré pendant les jours fériés dans la région de Rhénanie-du-Nord-Westphalie.</p>
                                    </li>
                                    <li data-list-text="2.16.7.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le support technique est disponible en allemand et en anglais.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.16.8.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Durée et résiliation du contrat</h1>
                                <p class="s15" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark5" class="a">Par dérogation à la clause 1.8.3</a>, <span class="p">aucune durée contractuelle minimale n&#39;est requise pour la version gratuite (Basic) du logiciel. Dans ce cas, le délai de préavis est de sept (7) jours à la fin du mois.</span></p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.17.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Demand Dashboard</p>
                        <ol id="l134">
                            <li data-list-text="2.17.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l135">
                                    <li data-list-text="2.17.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à disposition du client l’exploitation du logiciel par un accès à distance par internet (Software-as-a-Service, SaaS).</p>
                                    </li>
                                    <li data-list-text="2.17.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel est constamment développé et amélioré. Dans le cadre de ce développement, des fonctions partielles peuvent être modifiées ou supprimées, dans la mesure où ceci ne nuit pas à la bonne exécution du contrat pour le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.17.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l136">
                                    <li data-list-text="2.17.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel et les bases de données incluses sont protégés par le droit d’auteur. Le droit d’auteur, le droit des brevets, le droit des marques et tous les droits voisins sur le logiciel et les bases de données incluses sont la propriété exclusive de TecAlliance. Dans la mesure où les droits reviennent à des tiers, TecAlliance détient les droits d’exploitation correspondants.</p>
                                    </li>
                                    <li data-list-text="2.17.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre du volume déterminé dans ce contrat, TecAlliance accorde au client le droit simple, limité à la durée du présent contrat, non transmissible et non sous-licenciable, d’utiliser le logiciel et les bases de données incluses. Des droits plus étendus ne sont pas accordés au client.</p>
                                    </li>
                                    <li data-list-text="2.17.2.3.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel peut être uniquement utilisé par des personnes auxquelles TecAlliance a attribué une autorisation d’accès ou des données d’accès correspondant à l’accord contractuel. L’autorisation d’accès est personnelle et ne doit pas être transmise à d’autres personnes ni être utilisée par d’autres personnes.</p>
                                    </li>
                                    <li data-list-text="2.17.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les rapports établis avec le logiciel servent exclusivement à une utilisation du client en interne. Toute transmission à des tiers et/ou publication est interdite. En est exclue une transmission à des prestataires de service investis d’une mission pour le compte du client. La responsabilité du client de respecter ces conditions contractuelles n’est pas affectée par cette clause.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.17.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l137">
                                    <li data-list-text="2.17.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client remplira toutes les obligations nécessaires à la réalisation et au déroulement de la prestation de ce contrat en temps et en heure, intégralement et dans les règles, en particulier pour : le contrôle des prestations offertes par rapport à ses exigences ; la garantie que les exigences minimales de TecAlliance par rapport au matériel informatique et au logiciel utilisés par le client soient respectées ; le respect des indications de TecAlliance pour éviter les erreurs ; la protection des systèmes informatiques locaux contre une contamination par des logiciels malveillants ; une sauvegarde régulière des données et contenus transmis à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.17.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si un tiers viole des droits par les données et/ou des contenus mis à disposition du client par TecAlliance, TecAlliance est en droit de bloquer intégralement ou partiellement les contenus, provisoirement ou de façon permanente, si un doute justifié persiste, en raison d’éléments objectifs, sur la légalité des données et/ou des contenus. Dans ce cas, TecAlliance demandera au client de corriger la violation dans un délai approprié ou de prouver la légalité des données et/ou des contenus. Si le client n’accède pas à cette demande, TecAlliance est en droit de mettre fin au contrat pour un motif grave sans préjudice d’autres droits et recours sans avoir à observer de délai de préavis. Les frais résultants des mesures mentionnées pour TecAlliance sont facturés au client. Si le client est responsable de l’infraction, il fournira une réparation à TecAlliance pour le dommage subi et dégagera TecAlliance de toutes prétentions éventuelles de tiers. Les autres droits restent réservés.</p>
                                    </li>
                                    <li data-list-text="2.17.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de préserver le secret sur les autorisations d’exploitation et d’accès lui ayant été affectées ou affectées aux utilisateurs, ainsi que sur les autres instruments convenus d’identification et d’authentification, de les protéger d’un accès par des tiers et de ne pas les transmettre à des tiers non autorisés.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.17.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Gestion des utilisateurs</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance crée un utilisateur Admin dans le logiciel pour le client après le début du contrat. Le client est responsable de la gestion ultérieure des utilisateurs conforme au contrat.</p>
                            </li>
                            <li data-list-text="2.17.5.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Support technique</h1>
                                <ol id="l138">
                                    <li data-list-text="2.17.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">TecAlliance fournit un support pour le logiciel par e-mail.</p>
                                    </li>
                                    <li data-list-text="2.17.5.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les demandes de support technique du client doivent contenir les informations suivantes : Objet : Demand Dashboard + bref descriptif de l&#39;erreur + nom de l&#39;entreprise du client ; coordonnées de l&#39;utilisateur : prénom, nom, adresse e-mail ; descriptif détaillé de l&#39;erreur ; moment de l&#39;apparition de l&#39;erreur ; navigateur, , système d&#39;exploitation ; si possible, captures d&#39;écran appropriées.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.17.6.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="mailto:support.cgn@tecalliance.net" class="a" target="_blank">Les demandes de support technique comportant les indications  décrites  sont  prises  en  charge  par  TecAlliance exclusivement à l’adresse </a><a href="mailto:support.cgn@tecalliance.net" target="_blank">support.cgn@tecalliance.net.</a></p>
                            </li>
                            <li data-list-text="2.17.7.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Dans le logiciel, une aide à l’utilisateur est à disposition, comprenant une introduction à l’utilisation du logiciel.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.18.">
                        <h1 style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">TecDoc PMA</h1>
                        <ol id="l139">
                            <li data-list-text="2.18.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l140">
                                    <li data-list-text="2.18.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à disposition du client l’exploitation du logiciel par un accès à distance par internet (Software-as-a-Service, SaaS).</p>
                                    </li>
                                    <li data-list-text="2.18.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">. Dans le cadre du développement du logiciel, des fonctions partielles peuvent être modifiées ou supprimées, dans la mesure où ceci ne nuit pas à la bonne exécution du contrat pour le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Droits d’exploitation</h1>
                                <ol id="l141">
                                    <li data-list-text="2.18.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel et les bases de données incluses sont protégés par le droit d’auteur. Le droit d’auteur, le droit des brevets, le droit des marques et tous les droits voisins sur le logiciel et les bases de données incluses sont la propriété exclusive de TecAlliance. Dans la mesure où les droits reviennent à des tiers, TecAlliance détient les droits d’exploitation correspondants.</p>
                                    </li>
                                    <li data-list-text="2.18.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre du volume déterminé dans ce contrat, TecAlliance accorde au client le droit simple, limité à la durée du présent contrat, non transmissible et non sous-licenciable, d’utiliser le logiciel et les bases de données incluses. Des droits plus étendus ne sont pas accordés au client.</p>
                                    </li>
                                    <li data-list-text="2.18.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel peut être uniquement utilisé par des personnes auxquelles TecAlliance a attribué une autorisation d’accès ou des données d’accès correspondant à l’accord contractuel. L’autorisation d’accès est personnelle et ne doit pas être transmise à d’autres personnes ni être utilisée par d’autres personnes.</p>
                                    </li>
                                    <li data-list-text="2.18.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="mailto:TecDoc-PMA-notification@tecalliance.net" class="a" target="_blank">Le client ne peut accorder une autorisation d’accès ou des données d’accès qu’à des personnes travaillant pour son compte ou pour le compte d’une entreprise liée au sens de l’art. 15 de la loi sur les sociétés anonymes [Aktiengesellschaftsgesetz, AktG]. Si le client mandate un prestataire externe (1.4.1.2.5.), le prestataire reçoit uniquement  un  accès  après  l’avoir  notifié  à  TecDoc-PMA- </a>notification@tecalliance.net sous forme de texte ; les dispositions des art. 1.4.1.2.5. et 1.4.1.2.6. ne s’en trouvent pas affectées.</p>
                                    </li>
                                    <li data-list-text="2.18.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les rapports établis avec le logiciel servent exclusivement à une utilisation du client en interne. Toute transmission à des tiers et/ou publication est interdite. En est exclue une transmission à des prestataires de service investis d’une mission pour le compte du client. La responsabilité du client de respecter ces conditions contractuelles n’est pas affectée par cette clause.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l142">
                                    <li data-list-text="2.18.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client remplira toutes les obligations nécessaires à la réalisation et au déroulement de la prestation de ce contrat en temps et en heure, intégralement et dans les règles, en particulier pour : le contrôle des prestations offertes par rapport à ses exigences ; la garantie que les exigences minimales de TecAlliance par rapport au matériel informatique et au logiciel utilisés par le client soient respectées ; le respect des indications de TecAlliance pour éviter les erreurs ; la protection des systèmes informatiques locaux contre une contamination par des logiciels malveillants ; une sauvegarde régulière des données et contenus transmis à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.18.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si un tiers viole des droits par les données et/ou des contenus mis à disposition du client par TecAlliance, TecAlliance est en droit de bloquer intégralement ou partiellement les contenus, provisoirement ou de façon permanente, si un doute justifié persiste, en raison d’éléments objectifs, sur la légalité des données et/ou des contenus. Dans ce cas, TecAlliance demandera au client de corriger la violation dans un délai approprié ou de prouver la légalité des données et/ou des contenus. Si le client n’accède pas à cette demande, TecAlliance est en droit de mettre fin au contrat pour un motif grave</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">sans préjudice d’autres droits et recours sans avoir à observer de délai de préavis. Les frais résultants des mesures mentionnées pour TecAlliance sont facturés au client. Si le client est responsable de l’infraction, il fournira une réparation à TecAlliance pour le dommage subi et dégagera TecAlliance de toutes prétentions éventuelles de tiers. Les autres droits restent réservés.</p>
                                    </li>
                                    <li data-list-text="2.18.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de préserver le secret sur les autorisations d’exploitation et d’accès lui ayant été affectées ou affectées aux utilisateurs, ainsi que sur les autres instruments convenus d’identification et d’authentification, de les protéger d’un accès par des tiers et de ne pas les transmettre à des tiers non autorisés.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.4.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Support technique</h1>
                                <ol id="l143">
                                    <li data-list-text="2.18.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance fournit un support technique pour le logiciel par e-mail ou téléphone du lundi au vendredi de 8h00 à 18h00, sauf le 01/01, le 25/12 et le 26/12.</p>
                                    </li>
                                    <li data-list-text="2.18.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les demandes de support technique du client doivent contenir les informations suivantes : Objet : TecDoc PMA + bref descriptif de l&#39;erreur + nom de l&#39;entreprise du client ; coordonnées de l&#39;utilisateur : prénom, nom, adresse e-mail , descriptif détaillé de l&#39;erreur ; moment de l&#39;apparition de l&#39;erreur ; captures d&#39;écran appropriées et l&#39;ID du rapport affiché dans le système pour les rapports individuels destinés au support technique.</p>
                                    </li>
                                    <li data-list-text="2.18.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="mailto:support.cgn@tecalliance.net" class="a" target="_blank">Les demandes de support comportant les indications décrites sont prises en charge par TecAlliance exclusivement à l’adresse  </a>support.cgn@tecalliance.net.  Dans  les  cas  urgents, TecAlliance offre un support téléphonique au +49 221 6600 112.</p>
                                    </li>
                                    <li data-list-text="2.18.4.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Dans le logiciel, une aide à l’utilisateur est à disposition, comprenant une introduction à l’utilisation du logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.5.">
                                <h1 style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Exploitation de la base de données TecDoc Catalogue Data</h1>
                                <ol id="l144">
                                    <li data-list-text="2.18.5.1.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’exploitation du logiciel est uniquement possible dans le contexte de la base de données TecDoc Catalogue Data. Celle-ci contient la base de données assurant un bon fonctionnement du logiciel.</p>
                                    </li>
                                    <li data-list-text="2.18.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client dispose déjà d’une licence pour la base de données TecDoc Catalogue, il est autorisé à utiliser les données acquises selon la licence également à l’intérieur du logiciel. L’exploitation de la base de données TecDoc Catalogue Data à l’intérieur du logiciel doit être uniquement communiquée à TecAlliance par écrit et être confirmée par TecAlliance par écrit. En outre, toute modification de la licence relative à PMA doit être notifiée distinctement à TecAlliance par écrit. Une facturation supplémentaire pour l’exploitation de la base de données TecDoc Catalogue Data n’est pas effectuée.</p>
                                    </li>
                                    <li data-list-text="2.18.5.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client ne dispose pas de licence pour la base de données TecDoc Catalogue, l’exploitation des données TecDoc souhaitées dans le logiciel est couverte par la licence octroyée selon les règlementations des présentes conditions de vente. Pour cela, des frais particuliers sont facturés, qui sont comptabilisés dans l’offre. Une exploitation plus étendue de la base de données TecDoc Catalogue Data n’est pas comprise.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.6.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Utilisation de la base de données Vehicles in Operation</h1>
                                <ol id="l145">
                                    <li data-list-text="2.18.6.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’exploitation du logiciel dans toute son étendue est exclusivement autorisée pour les parties de la base de données Vehicles in Operation proposées.</p>
                                    </li>
                                    <li data-list-text="2.18.6.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client dispose déjà d’une licence pour la base de données Vehicles in Operation, il est autorisé à utiliser les données acquises selon la licence également à l’intérieur du logiciel. L’exploitation de la base de données Vehicles in Operation à l’intérieur du logiciel doit être uniquement communiquée à TecAlliance sous</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">forme littérale et être confirmée par TecAlliance sous forme littérale. En outre, toute modification de la licence relative à PMA doit être notifiée distinctement à TecAlliance par écrit. Une facturation supplémentaire pour l’exploitation de la base de données Vehicles in Operation n’est pas effectuée.</p>
                                    </li>
                                    <li data-list-text="2.18.6.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client ne dispose pas de licence pour la base de données Vehicles in Operation, l’exploitation des données souhaitées dans le logiciel est couverte par la licence octroyée selon les règlementations des présentes conditions de vente. Pour cela, des frais particuliers sont facturés, qui sont comptabilisés dans l’offre. Une exploitation plus étendue de la base de données Vehicles in Operation n’est pas comprise.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.18.7.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Exploitation de la base de données TecDoc Usage Data</h1>
                                <ol id="l146">
                                    <li data-list-text="2.18.7.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’exploitation du logiciel est uniquement possible en relation avec une licence pour la base de données TecDoc Usage Data. Celle-ci contient la base de données assurant un bon fonctionnement du logiciel.</p>
                                    </li>
                                    <li data-list-text="2.18.7.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client dispose déjà d’une licence pour Demand Dashboard, il est autorisé à également utiliser les données acquises selon la licence à l’intérieur du logiciel. L’exploitation doit être uniquement communiquée à TecAlliance sous forme littérale et être confirmée par TecAlliance sous forme littérale. Aucun autre calcul n&#39;est effectué.</p>
                                    </li>
                                    <li data-list-text="2.18.7.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client ne dispose pas de licence pour Demand Dashboard, l’exploitation des données TecDoc Web Usage Data souhaitée dans le logiciel est couverte par la licence octroyée selon les règlementations des présentes conditions de vente. Pour cela, des frais particuliers sont facturés, qui sont comptabilisés dans l’offre. Une exploitation plus étendue de la base de données TecDoc Usage Data ou du Demand Dashboard n’est pas comprise.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.19.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Data Services</p>
                        <ol id="l147">
                            <li data-list-text="2.19.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l148">
                                    <li data-list-text="2.19.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’objet de la prestation est le traitement et la préparation des données fournies par le client et le transfert de ces données au format de données valide du catalogue TecAlliance afin de les publier, après approbation du client, conformément aux dispositions du contrat du fournisseur de données, et de les distribuer aux utilisateurs de données. Les services spécifiques à fournir par TecAlliance sont mentionnés dans l’offre.</p>
                                    </li>
                                    <li data-list-text="2.19.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les services du domaine des Data Services sont fournis exclusivement aux clients qui ont conclu un accord de fournisseur de données valide avec TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.19.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la fourniture des services, TecAlliance prend en compte uniquement les informations fournies par le client et/ ou ses fournisseurs de services, telles que les informations sur les articles, les applications, les références croisées, les documents, etc. TecAlliance n’utilise généralement pas d’autres sources de données.</p>
                                    </li>
                                    <li data-list-text="2.19.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance transfère les données d’article du client dans le système de classification des données de produit TecAlliance pour qu’elles puissent y être classifiées et attribuées. Si nécessaire, des articles génériques et/ ou des attributs non existants dans le système d’identification de la classe de produits TecAlliance doivent d’abord être créés dans les données de référence TecAlliance. Les données d’article concernées ne peuvent donc généralement être traitées qu’à la prochaine date possible.</p>
                                    </li>
                                    <li data-list-text="2.19.1.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les applications client sont gérées exclusivement en se fondant sur la base de données de véhicules TecAlliance. Si nécessaire, les véhicules non existants dans la base de données de véhicules TecAlliance doivent d’abord être créés dans les données de référence</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">TecAlliance.  Les  applications  concernées  ne  peuvent  donc</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">généralement être traitées qu’à la prochaine date possible.</p>
                                    </li>
                                    <li data-list-text="2.19.1.6.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la fourniture du service, certaines données OE et/ ou affectations utilisées ne disposent pas d’une couverture totale. À cet égard, le client reconnaît les références OE et/ ou affectations manquant(e)s dans le traitement des données comme une prestation contractuelle.</p>
                                    </li>
                                    <li data-list-text="2.19.1.7.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le service dû au titre du présent contrat s’étend exclusivement à la date cible indiquée dans l’offre et comprend un (1) traitement de données conformément à la portée des services décrits dans l’offre et les présentes CGV.</p>
                                    </li>
                                    <li data-list-text="2.19.1.8.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut utiliser les services fournis par un ou plusieurs personnes auxiliaires (sous-traitants). La personne auxiliaire doit posséder les qualifications professionnelles requises pour la prestation du service. TecAlliance n’est pas tenu de divulguer au client le recours à des personnes auxiliaires.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.19.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Devoirs de collaboration du client</h1>
                                <ol id="l149">
                                    <li data-list-text="2.19.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit désigner TecAlliance sous forme littérale en tant que personne de contact et représentant qui peut être contacté pendant les heures ouvrables habituelles pour répondre aux demandes de renseignements et qui est habilité à statuer sur les questions litigieuses.</p>
                                    </li>
                                    <li data-list-text="2.19.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit s’assurer que la date de livraison définie dans l’offre est respectée. À cette date au plus tard, les données du client doivent être disponibles pour TecAlliance pour garantir un traitement dans les délais.</p>
                                    </li>
                                    <li data-list-text="2.19.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Si le client ne respecte pas le délai de livraison, TecAlliance en modifiera la date et en avisera le client.</p>
                                    </li>
                                    <li data-list-text="2.19.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données fournies par le client doivent être conformes aux exigences de ces CGV. Si les données ne répondent pas à une ou plusieurs exigences des présentes CGV, TecAlliance ne sera pas en mesure de fournir les services ou ne sera plus en mesure de le faire en temps voulu. Dans ce cas, TecAlliance informera le client des lacunes dans la livraison des données et de leurs conséquences (non- acceptation des données, frais supplémentaires lors de la fourniture des services) et s’accordera avec ce dernier pour déterminer la procédure à suivre.</p>
                                    </li>
                                    <li data-list-text="2.19.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après traitement par TecAlliance, les données seront transmises au client pour examen et approbation. Les données traitées sont considérées comme approuvées, à moins que le client ne dépose dans un délai de trois (3) jours une réclamation sous forme littérale concernant les services fournis.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.19.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Spécifications concernant les données fournies</h1>
                                <ol id="l150">
                                    <li data-list-text="2.19.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance ne peut gérer que des enregistrements univoques et logiques. Des différences d’orthographes ou des informations contradictoires ou invraisemblables ne peuvent pas être traitées.</p>
                                    </li>
                                    <li data-list-text="2.19.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les images et les graphiques ne peuvent être traités par TecAlliance que dans les formats BMP ou JPG. Les images ne doivent pas dépasser une taille de 600 x 400 px. Les logos ne doivent pas dépasser une taille de 130 x 90 px.</p>
                                    </li>
                                    <li data-list-text="2.19.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les documents PDF doivent au moins être disponibles en allemand, en anglais, en espagnol, en français, en italien et en néerlandais.</p>
                                    </li>
                                    <li data-list-text="2.19.3.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les images, les documents et les modules de texte doivent être attribués aux numéros d’article et, le cas échéant, aux liens article- véhicule du client.</p>
                                    </li>
                                    <li data-list-text="2.19.3.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les noms de fichier ne doivent pas dépasser 30 caractères et ne doivent pas contenir le caractère de point (.).</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                    <li data-list-text="2.19.3.6.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la saisie des dimensions et des unités, il convient de s’assurer que la spécification de la dimension/ de l’unité est également renseignée (par exemple, longueur en mm, diamètre de centrage en mm, diamètre du filetage extérieur en pouces).</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="2.20.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Trade Brands</p>
                        <ol id="l151">
                            <li data-list-text="2.20.1.">
                                <h1 style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l152">
                                    <li data-list-text="2.20.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’objet de la prestation est le traitement et la préparation des données fournies par le client et le transfert de ces données au format de données valide du catalogue TecAlliance afin de les publier, après approbation du client, dans l’un des services Web TecAlliance achetés par le client. Les services spécifiques à fournir par TecAlliance sont mentionnés dans le descriptif des prestations et dans l’offre.</p>
                                    </li>
                                    <li data-list-text="2.20.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les services fournis dans le domaine des Trade Brands sont fournis exclusivement aux clients qui ont conclu un accord lié au catalogue TecDoc White Label ou au service Web TecDoc Catalogue Data avec TecAlliance.</p>
                                    </li>
                                    <li data-list-text="2.20.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la fourniture des services, TecAlliance prend en compte uniquement les informations fournies par le client et/ ou ses fournisseurs de services, telles que les informations sur les articles, les applications, les références croisées, les documents, etc. TecAlliance n’utilise généralement pas d’autres sources de données.</p>
                                    </li>
                                    <li data-list-text="2.20.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance transfère les données d’article du client dans le système de classification des données de produit TecAlliance pour qu’elles puissent y être classifiées et attribuées. Si nécessaire, des articles génériques et/ ou des attributs non existants dans le système d’identification de la classe de produits TecAlliance doivent d’abord être créés dans les données de référence TecAlliance. Les données d’article concernées ne peuvent donc généralement être traitées qu’à la prochaine date possible.</p>
                                    </li>
                                    <li data-list-text="2.20.1.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les applications client sont gérées exclusivement en se fondant sur la base de données de véhicules TecAlliance. Si nécessaire, les véhicules non existants dans la base de données de véhicules TecAlliance doivent d’abord être créés dans les données de référence TecAlliance.  Les  applications  concernées  ne  peuvent  donc généralement être traitées qu’à la prochaine date possible.</p>
                                    </li>
                                    <li data-list-text="2.20.1.6.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la fourniture du service, il se peut que certaines données OE et / ou affectations utilisées ne disposent pas d’une couverture totale. À cet égard, le client reconnaît les références OE et/ou affectations manquantes dans le traitement des données comme une prestation contractuelle.</p>
                                    </li>
                                    <li data-list-text="2.20.1.7.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le service dû au titre du présent contrat s’étend exclusivement à la date cible indiquée dans l’offre et comprend un (1) traitement de données conformément à la portée des services décrits.</p>
                                    </li>
                                    <li data-list-text="2.20.1.8.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut utiliser les services fournis par un ou plusieurs personnes auxiliaires (sous-traitants). La personne auxiliaire doit posséder les qualifications professionnelles requises pour la prestation du service. TecAlliance n’est pas tenu de divulguer au client le recours à des personnes auxiliaires.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.20.2.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Devoirs de collaboration du client</h1>
                                <ol id="l153">
                                    <li data-list-text="2.20.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit désigner TecAlliance sous forme littérale en tant que personne de contact et représentant qui peut être contacté pendant les heures ouvrables habituelles pour répondre aux demandes de renseignements et qui est habilité à statuer sur les questions litigieuses.</p>
                                    </li>
                                    <li data-list-text="2.20.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit s’assurer que la date de livraison définie dans l’offre est respectée. À cette date au plus tard, les données du client doivent être disponibles pour TecAlliance pour garantir un traitement dans les délais.</p>
                                    </li>
                                    <li data-list-text="2.20.2.3.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Si le client ne respecte pas le délai de livraison, TecAlliance en modifiera la date et en avisera le client.</p>
                                    </li>
                                    <li data-list-text="2.20.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données fournies par le client doivent être conformes aux exigences de ces CGV. Si les données ne répondent pas à une ou plusieurs exigences des présentes CGV, TecAlliance ne sera pas en mesure de fournir les services ou ne sera plus en mesure de le faire en temps voulu. Dans ce cas, TecAlliance informera le client des lacunes dans la livraison des données et de leurs conséquences (non- acceptation des données, frais supplémentaires lors de la fourniture des services) et s’accordera avec ce dernier pour déterminer la procédure à suivre.</p>
                                    </li>
                                    <li data-list-text="2.20.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après traitement par TecAlliance, les données seront transmises au client pour examen et approbation. Les données traitées sont considérées comme approuvées, à moins que le client ne dépose dans un délai de trois (3) jours une réclamation sous forme littérale concernant les services fournis.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="2.20.3.">
                                <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Spécifications concernant les données fournies</h1>
                                <ol id="l154">
                                    <li data-list-text="2.20.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance ne peut gérer que des enregistrements univoques et logiques. Des différences d’orthographes ou des informations contradictoires ou invraisemblables ne peuvent pas être traitées.</p>
                                    </li>
                                    <li data-list-text="2.20.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les images et les graphiques ne peuvent être traités par TecAlliance que dans les formats BMP ou JPG. Les images ne doivent pas dépasser une taille de 600 x 400 px. Les logos ne doivent pas dépasser une taille de 130 x 90 px.</p>
                                    </li>
                                    <li data-list-text="2.20.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les documents PDF doivent au moins être disponibles en allemand, en anglais, en espagnol, en français, en italien et en néerlandais.</p>
                                    </li>
                                    <li data-list-text="2.20.3.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les images, les documents et les modules de texte doivent être attribués aux numéros d’article et, le cas échéant, aux liens article- véhicule du client.</p>
                                    </li>
                                    <li data-list-text="2.20.3.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les noms de fichier ne doivent pas dépasser 30 caractères et ne doivent pas contenir le caractère de point (.).</p>
                                    </li>
                                    <li data-list-text="2.20.3.6.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lors de la saisie des dimensions et des unités, il convient de s’assurer que la spécification de la dimension/ de l’unité est également renseignée (par exemple, longueur en mm, diamètre de centrage en mm, diamètre du filetage extérieur en pouces).</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="3.">
                    <p class="s16" style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: left;"><a name="bookmark12">&zwnj;</a>Conditions spéciales de vente TecRMI</p>
                    <ol id="l155">
                        <li data-list-text="3.1.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Repair and Maintenance Data</p>
                        <ol id="l156">
                            <li data-list-text="3.1.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <ol id="l157">
                                    <li data-list-text="3.1.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données mises à disposition proviennent, si possible, de constructeurs et d’importateurs automobiles. Les informations relatives à différents pays peuvent indiquer différents taux de couverture des marques des constructeurs automobiles. Certaines collectes de données de TecAlliance sont identifiées clairement dans la base de données.</p>
                                    </li>
                                    <li data-list-text="3.1.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les données mises à disposition sont disponibles dans les langues convenues.</p>
                                    </li>
                                    <li data-list-text="3.1.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">TecAlliance se réserve le droit de modifier la structure des interfaces avec un préavis.</p>
                                    </li>
                                    <li data-list-text="3.1.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données, informations et systèmes présentent des informations de remplissage différentes et sont progressivement créés, développés et gérés par des mises à jour, en tenant compte de l’évolution respective sur le marché. Le nombre de marques, de modèles et types, ainsi que les informations et la documentation sont sujets à variation. Les données de remplissage dépendent des besoins du marché, les priorités sont hiérarchisées en fonction du nombre d’immatriculations en Europe. Le nombre exact d’informations disponibles  concernant  les  véhicules  reste  donc  indéterminé contractuellement.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.1.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue de l’exploitation</h1>
                                <ol id="l158">
                                    <li data-list-text="3.1.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le droit d’exploitation du client englobe les modules, les pays et les langues dont il a été convenu dans l’offre.</p>
                                    </li>
                                    <li data-list-text="3.1.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’identification des véhicules et des activités est réalisé par le TecDoc-Standard. Le client confirme qu’il est autorisé par TecAlliance à exploiter les données de référence TecDoc.</p>
                                    </li>
                                    <li data-list-text="3.1.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les produits logiciels du client doivent être créés de manière à ce qu’ils affichent exclusivement les données les plus récentes du service en ligne.</p>
                                    </li>
                                    <li data-list-text="3.1.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Il est interdit de supprimer les mentions de copyright de la base de données et de son contenu.</p>
                                    </li>
                                    <li data-list-text="3.1.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de créer des identifiants individuels pour chaque utilisateur final à qui il donne accès aux données mises à disposition et de communiquer ces identifiants à TecAlliance lors de l’exploitation des données. Les erreurs dans les données des identifiants sont considérées, évaluées et facturées comme des utilisateurs séparés.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.1.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l159">
                                    <li data-list-text="3.1.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’apposer le logo « TecRMI inside » mis à disposition par TecAlliance après la conclusion du contrat, en couleur ou en noir et blanc, sur la page d’accueil de l’application ou du média sur lequel la base de données est publiée. Il est interdit de modifier le logo. Seule la taille du logo peut être modifiée, mais les proportions des pages doivent rester les mêmes, et la largeur ne doit pas être inférieure à 100 pixels, soit 3 cm.</p>
                                    </li>
                                    <li data-list-text="3.1.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure où le client charge des tiers de l’intégration des données dans ses systèmes, il est tenu de conclure avec ces derniers un accord qui garantisse le respect des présentes conditions de vente.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.1.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Support technique</h1>
                                <ol id="l160">
                                    <li data-list-text="3.1.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le support technique de TecAlliance répond aux demandes techniques relatives à Repair and Maintenance Information les jours suivants : du lundi au vendredi, de 8h00 à 17h00 MEZ, sauf les jours fériés du Bade-Wurtemberg.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.1.5.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Responsabilité</h1>
                                <ol id="l161">
                                    <li data-list-text="3.1.5.1.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Malgré une planification et un travail soigneux, il ne peut être exclu, dans tous les cas, que des informations ou des résultats soient incorrects lors de la production de données, du transfert de savoir-faire ou de processus informatiques. Ainsi, la production et la fourniture s’effectuent sur la base des informations les plus pertinentes dont nous disposons et à la condition que les données sources, telles que les informations du fabricant, soient correctes. En conséquence, TecAlliance exclut toute responsabilité en cas de données ou de résultats incorrects dus à des erreurs des données et informations fournies par des tiers à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="3.1.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Ceci n’affecte pas la responsabilité pour dol ou négligence grave. En principe et sauf si la loi en dispose autrement, la responsabilité est limitée à 1 500 EUR par cas.</p>
                                    </li>
                                    <li data-list-text="3.1.5.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client s’engage à intégrer dans son produit une décharge de responsabilité identique à celle du produit dans lequel les données et informations de TecAlliance sont utilisées. L’utilisateur final doit accepter la décharge de responsabilité, par exemple en acceptant les clauses correspondantes dans le produit ou dans les conditions générales de licence et d’exploitation des contrats.</p>
                                    </li>
                                    <li data-list-text="3.1.5.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas d’une action en dommages et intérêts introduite par le client, les réglementations suivantes doivent être respectées. Si ces réglementations ne sont pas respectées, TecAlliance GmbH ne procédera à aucune indemnisation jusqu’à la preuve complète de la causalité d’une possible information erronée d’un dommage, que le demandeur est tenu de prouver à ses propres frais.</p>
                                    <ol id="l162">
                                        <li data-list-text="3.1.5.4.1.">
                                            <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Notification de l’événement dommageable</p>
                                            <ol id="l163">
                                                <li data-list-text="3.1.5.4.1.1.">
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Tout événement dommageable causé par une information erronée et susceptible de conduire à des demandes d’indemnisation à l’encontre de TecAlliance GmbH doit être signalé à TecAlliance avant réparation.</p>
                                                </li>
                                                <li data-list-text="3.1.5.4.1.2.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="mailto:support.wkh@tecalliance.net" class="a" target="_blank">Cette  notification  doit  se  faire  immédiatement, généralement dans un délai de vingt-quatre (24) heures après la survenue de l’événement dommageable sous forme écrite ou par e-mail à </a><a href="mailto:support.wkh@tecalliance.net" target="_blank">support.wkh@tecalliance.net.</a></p>
                                                </li>
                                                <li data-list-text="3.1.5.4.1.3.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La notification du dommage doit impérativement contenir les informations suivantes : description du dommage, y compris l’origine du dommage avec des photos pertinentes ; preuve que l’information à l’origine du dommage provient de TecAlliance (extrait des informations RMI et surtout correction de l’information) ; copie de la commande de garage originale avec signature du client ou facture de la commande lors de laquelle le dommage est survenu ; copie de la facture d’achat de la pièce à l’origine du dommage ; devis pour la remise en état du dommage ; informations spécifiques au véhicule : marque, modèle, type, puissance, VIN, première immatriculation, numéro du moteur</p>
                                                </li>
                                            </ol>
                                        </li>
                                        <li data-list-text="3.1.5.4.2.">
                                            <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Suite de la procédure</p>
                                            <ol id="l164">
                                                <li data-list-text="3.1.5.4.2.1.">
                                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après la réception chez TecAlliance de la notification complète de l’événement dommageable, le client recevra le jour ouvrable suivant une réponse l’informant de la suite de la procédure.</p>
                                                </li>
                                                <li data-list-text="3.1.5.4.2.2.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Cette réponse peut comprendre : l’autorisation de réparation et la confirmation de la prise en charge des coûts par TecAlliance jusqu’à un certain montant, ou l’ouverture d’une enquête sur le dommage signalé par TecAlliance-même ou par un tiers mandaté par TecAlliance.</p>
                                                </li>
                                                <li data-list-text="3.1.5.4.2.3.">
                                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si une enquête sur l’événement dommageable conduite par TecAlliance devait démontrer que la demande de dommages et intérêts est justifiée, TecAlliance prendrait alors en charge, outre les frais de réparation nécessaires, l’ensemble des frais d’enquête ainsi que les dommages causés par l’enquête et le préjudice documenté subi par</p>
                                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                                <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">le client en raison du retard. Par contre, si le résultat d’enquête ne confirme pas que la demande de dommages et intérêts est justifiée, TecAlliance se réserve le droit de facturer les frais liés à l’enquête au client qui a signalé le dommage.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="3.2.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Expert Hotline</p>
                        <ol id="l165">
                            <li data-list-text="3.2.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l166">
                                    <li data-list-text="3.2.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’objet des prestations consiste dans la mise à disposition et la gestion d’une assistance téléphonique technique avec des informations sur les véhicules VL, conformément au descriptif des prestations.</p>
                                    </li>
                                    <li data-list-text="3.2.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données envoyées aux clients et aux utilisateurs de l’assistance téléphonique dans le cadre de la fourniture des prestations sont limitées à l’entreprise du client et aux utilisateurs de l’assistance téléphonique. Il est interdit au client de transmettre ou de vendre les données.</p>
                                    </li>
                                    <li data-list-text="3.2.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre d’un rapport mensuel de l’assistance téléphonique, le client reçoit un aperçu des cas traités, y compris les données clients et le problème posé.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.2.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Devoirs de collaboration du client</h1>
                                <ol id="l167">
                                    <li data-list-text="3.2.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a name="bookmark13">&zwnj;</a>Pour exclure tout abus ou utilisation illicite de l’assistance téléphonique au détriment du client, le client est tenu de transmettre à TecAlliance tous les mois, par voie électronique, des informations sur les utilisateurs autorisés dans un format standardisé défini par TecAlliance.</p>
                                    </li>
                                    <li data-list-text="3.2.2.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">TecAlliance ne répond pas aux questions des utilisateurs</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="#bookmark13" class="a">qui n’ont pas été notifiés conformément au point </a><a href="#bookmark13">3.2.2.1.</a></p>
                                    </li>
                                    <li data-list-text="3.2.2.3.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark13" class="a">Toute modification intervenant en dehors de l’actualisation mensuelle conformément à l’article </a>3.2.2.1 sera facturée distinctement par TecAlliance.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.2.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Responsabilité</h1>
                                <ol id="l168">
                                    <li data-list-text="3.2.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En raison du caractère principalement manuel du travail de recherche et pour répondre aux questions, il est en principe impossible d’exclure la survenance d’erreurs de transmission. Ainsi, la réponse, la production et la fourniture des informations recherchées s’effectuent sur la base des informations les plus pertinentes dont nous disposons et à la condition que les données sources, telles que les informations du fabricant, soient correctes.</p>
                                    </li>
                                    <li data-list-text="3.2.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En conséquence, TecAlliance exclut toute responsabilité en cas de données ou de résultats incorrects dus à des erreurs des données et informations fournies par des tiers à TecAlliance. Dans ce cas, le client supporte la charge de la preuve.</p>
                                    </li>
                                    <li data-list-text="3.2.3.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Ceci n’affecte pas la responsabilité pour dol ou négligence</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">grave.</p>
                                    </li>
                                    <li data-list-text="3.2.3.4.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En principe et sauf si la loi en dispose autrement, la responsabilité est limitée à la valeur du produit ou à la livraison de données concernée.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="3.3.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Service Book</p>
                        <ol id="l169">
                            <li data-list-text="3.3.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Conclusion du contrat</h1>
                                <ol id="l170">
                                    <li data-list-text="3.3.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: justify;">La prestation de service « TecRMI » peut être commandée</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">par l’intermédiaire d’un formulaire électronique.</p>
                                    </li>
                                    <li data-list-text="3.3.1.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’envoi du formulaire de commande électronique dûment rempli vaut offre du client de contracter un contrat relatif à la prestation de service « TecRMI Service Book ».</p>
                                    </li>
                                    <li data-list-text="3.3.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dès réception de la commande par TecAlliance, le client reçoit un e-mail confirmant la réception de la commande par TecAlliance et comprenant un récapitulatif de la commande (confirmation de réception). Cette confirmation ne vaut pas acceptation de l&#39;offre de contracter du client.</p>
                                    </li>
                                    <li data-list-text="3.3.1.4.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après la vérification de la commande par TecAlliance, le client reçoit un e-mail confirmant sa commande (confirmation de commande). Cette confirmation vaut acceptation de l&#39;offre de contracter du client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.3.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l171">
                                    <li data-list-text="3.3.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La prestation concerne la fourniture de la prestation de service « TecRMI Service Book » par TecAlliance conformément à l’offre, à la description des prestations et aux dispositions du contrat.</p>
                                    </li>
                                    <li data-list-text="3.3.2.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Inscriptions dans le carnet d&#39;entretien électronique</p>
                                    <ol id="l172">
                                        <li data-list-text="3.3.2.2.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre de la prestation de service « TecRMI Service Book », TecAlliance inscrit dans le carnet d’entretien électronique du constructeur, pour le compte du client, les opérations effectuées par le client sur le véhicule.</p>
                                        </li>
                                        <li data-list-text="3.3.2.2.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">TecAlliance procède aux inscriptions au nom et pour le compte du client.</p>
                                        </li>
                                        <li data-list-text="3.3.2.2.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance a le droit de facturer au client tous les frais encourus pour l’utilisation des livrets de service électroniques du constructeur.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="3.3.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Consultation des carnets d&#39;entretien</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En outre, TecAlliance permet au client de consulter toutes les inscriptions figurant dans le carnet d&#39;entretien d&#39;un véhicule auprès du constructeur.</p>
                                    </li>
                                    <li data-list-text="3.3.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le contrat stipule expressément que TecAlliance peut uniquement fournir les prestations de service mentionnées si le constructeur propose un carnet d&#39;entretien électronique et s&#39;il autorise les inscriptions dans le carnet d&#39;entretien, les rectifications et les consultations par un prestataire.</p>
                                    </li>
                                    <li data-list-text="3.3.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">TecAlliance  ne  répond  pas  des  retards  et dysfonctionnements ne relevant pas de sa sphère d&#39;influence dans la fourniture de la prestation de service.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.3.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l173">
                                    <li data-list-text="3.3.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client donne pouvoir à TecAlliance pour procéder, au nom et pour le compte du client, à tous les actes nécessaires à la fourniture de la prestation de service « TecRMI Service Book » sur les portails  des  constructeurs.  Il  s&#39;agit  notamment,  mais  pas exclusivement : de la création d&#39;une boîte aux lettres électronique spécifique au client, de la création et de la gestion des accès, de la saisie, la correction et la consultation des carnets d&#39;entretien électroniques, de la communication avec les constructeurs automobiles</p>
                                    </li>
                                    <li data-list-text="3.3.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client a déjà créé un accès aux carnets de service électroniques du constructeur avant la conclusion du contrat, il est tenu de fournir gratuitement à TecAlliance cet accès (accès actif, données d’accès).</p>
                                    </li>
                                    <li data-list-text="3.3.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Pour chaque opération de maintenance effectuée et traitée par TecAlliance en vertu du présent contrat, le client met à la disposition de TecAlliance les données suivantes, sous forme électronique : <i>Données relatives au véhicule </i>: NIV, code constructeur/CNIT, kilométrage, date de première mise en circulation</p>
                                    <p class="s17" style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">Données de maintenance <span class="p">:    date de la maintenance, travaux effectués, pièces installées.</span></p>
                                    <p class="s17" style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">Données relatives au constructeur : <span class="p">selon les exigences du constructeur Il convient de remettre les données à TecAlliance dans un délai de deux</span></p>
                                    <ol id="l174">
                                        <li data-list-text="(2)">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">jours ouvrables au moins avant le terme d&#39;un éventuel délai d&#39;inscription défini par le constructeur.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="3.3.3.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: left;">Les données optionnelles saisies dans le carnet d&#39;entretien numérique des constructeurs ne font pas partie du présent contrat.</p>
                                    </li>
                                    <li data-list-text="3.3.3.5.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: left;">Les données mises à disposition par le client sont traitées</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">par TecAlliance telles qu’elles sont transmises. Le client répond seul de</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">l&#39;exactitude des données. Toute responsabilité de TecAlliance à cet égard est exclue.</p>
                                    </li>
                                    <li data-list-text="3.3.3.6.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Après l’enregistrement, le client est tenu de télécharger le rapport original du constructeur sur le portail DSB et de vérifier l’exactitude  de  son  contenu.  Toute  erreur  doit  être  signalée immédiatement. TecAlliance décline toute responsabilité pour les erreurs signalées tardivement.</p>
                                    </li>
                                    <li data-list-text="3.3.3.7.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de cessation du contrat, TecAlliance remet gratuitement au client toutes les données d&#39;accès créées au nom du client pour les carnets d&#39;entretien électroniques des constructeurs automobiles. Le client est tenu de transférer ces accès à lui-même ou à un tiers dans les 8 semaines suivant la fin du contrat.</p>
                                    </li>
                                    <li data-list-text="3.3.3.8.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client ne satisfait pas, pas entièrement ou pas en temps utile aux obligations prévues par la présente section, TecAlliance n&#39;est pas tenue au niveau de maintenance convenu. Dans ce cas, TecAlliance peut facturer séparément au client l&#39;éventuel supplément de coût en résultant.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="3.3.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Conditions de paiement</h1>
                                <ol id="l175">
                                    <li data-list-text="3.3.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La fourniture de la prestation de service « TecRMI Service Book » suppose que le client donne à TecAlliance un mandat de prélèvement SEPA B2B valable pendant toute la durée du contrat. Aucun autre mode de paiement n&#39;est proposé.</p>
                                    </li>
                                    <li data-list-text="3.3.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les prestations de services fournies sont facturées à la fin du mois.</p>
                                    </li>
                                    <li data-list-text="3.3.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de s’assurer que le compte de règlement est suffisamment approvisionné. Si l’encaissement est effectué sur le compte d’un tiers, le client doit immédiatement informer le tiers titulaire du compte de la date et du montant de l’encaissement annoncé. Si le prélèvement automatique n’est pas honoré, TecAlliance est en droit de réclamer les frais de refus (frais de retour de prélèvement) dans la mesure où le client en est responsable. Le client peut apporter la preuve de l’absence de dommage ou d’un dommage inférieur.</p>
                                    <ol id="l176">
                                        <ol id="l177">
                                            <li data-list-text="3.4">
                                                <h1 style="padding-left: 17pt;text-indent: -11pt;text-align: justify;">Document Scanner</h1>
                                                <ol id="l178">
                                                <li data-list-text="3.4.1.">
                                                    <h1 style="padding-left: 22pt;text-indent: -17pt;text-align: justify;">Contenu de la prestation</h1>
                                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le TecRMI Document Scanner traite les fichiers images des documents, reconnaît les informations des champs prédéfinis et extrait le contenu dans un format pouvant être traité.</p>
                                                    <ol id="l179">
                                                        <ol id="l180">
                                                            <ol id="l181">
                                                            <li data-list-text="3.4.2">
                                                                <h1 style="padding-left: 22pt;text-indent: -16pt;line-height: 8pt;text-align: justify;">Étendue de l’exploitation</h1>
                                                                <ol id="l182">
                                                                    <li data-list-text="3.4.2.1.">
                                                                        <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le TecRMI Document Scanner est mis à disposition sous forme d’interface (API) et peut être intégré dans d’autres systèmes. L’application nécessite une caméra ou une image du contenu à analyser. L’analyse des données s&#39;effectue exclusivement sur la base des fichiers transmis.</p>
                                                                    </li>
                                                                    <li data-list-text="3.4.2.2.">
                                                                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données techniques des véhicules extraites des fichiers scannés  restent  enregistrées  dans  le  système,  les  données personnelles du détenteur du véhicule, sous une forme structurée, étant supprimées chaque jour. Les photos téléchargées sont supprimées chaque mois. Pendant ce mois, les photos peuvent être de nouveau utilisées pour tester le modèle de lecture (exclusivement aux fins d’amélioration de la qualité de lecture).</p>
                                                                    </li>
                                                                </ol>
                                                            </li>
                                                            </ol>
                                                        </ol>
                                                    </ol>
                                                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Au demeurant, il convient 1.14. GTC TecAlliance.</p>
                                                </li>
                                                <li data-list-text="3.4.2.">
                                                    <h1 style="padding-top: 1pt;padding-left: 24pt;text-indent: -18pt;text-align: justify;">Obligations du cocontractant</h1>
                                                </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </ol>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le cocontractant est tenu au respect des règles prévues par le règlement général sur la protection des données (RGPD). Si les données personnelles du détenteur du véhicule ne sont pas noircies dans les fichiers images transmis au TecRMI Document Scanner, TecAlliance présume que le client final y consent et qu’elle peut traiter les données.</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le stockage des données à d’autres fins requiert le consentement distinct du <b>consommateur final </b>; le cocontractant doit recueillir ce consentement du consommateur final pour TecAlliance.</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="4.">
                    <p class="s18" style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: left;"><a name="bookmark14">&zwnj;</a>Conditions spéciales de vente TecCom</p>
                    <ol id="l183">
                        <li data-list-text="4.1.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Licence partenaire exclusif</p>
                        <ol id="l184">
                            <li data-list-text="4.1.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <ol id="l185">
                                    <li data-list-text="4.1.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">À la conclusion du contrat, le client, en qualité de fabricant de produits destinés au marché indépendant des pièces de rechange automobiles, acquiert le droit de vendre ses produits sur TecCom. L’utilisation des modules TecCom et l’utilisation des services sont basées sur des conditions contractuelles distinctes.</p>
                                    </li>
                                    <li data-list-text="4.1.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance fournit au client un logiciel de base et une documentation appropriés pour connecter ses systèmes ERP à TecCom et pour permettre la communication entre le client et ses acheteurs.</p>
                                    </li>
                                    <li data-list-text="4.1.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le client peut indiquer sa participation à TecCom en utilisant un logo fourni par TecAlliance.</p>
                                    </li>
                                    <li data-list-text="4.1.1.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Le client peut participer aux groupes de travail mis en place par TecAlliance pour le développement de TecCom.</p>
                                    </li>
                                    <li data-list-text="4.1.1.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Sauf  accord  contraire,  les  dispositions  ci-dessus s&#39;appliquent également aux entreprises liées conformément aux art. 15 et ss. de la loi Akt.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.1.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l186">
                                    <li data-list-text="4.1.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit indiquer à TecAlliance par écrit le nom d&#39;un interlocuteur et représentant qui peut être contacté pendant les heures de bureau habituelles pour répondre aux questions et qui est habilité à statuer sur les questions litigieuses.</p>
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">4.1.2.1.1.1. Le client est tenu de vérifier l&#39;exactitude, l&#39;actualité et l&#39;exhaustivité des données fournies par lui-même ou par ses clients avec la diligence requise. TecAlliance décline toute responsabilité à cet égard.</p>
                                    </li>
                                    <li data-list-text="4.1.2.2.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Redevances</h1>
                                    <ol id="l187">
                                        <li data-list-text="4.1.2.2.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les redevances à payer par le client résultent de l&#39;offre et sont constituées des frais initiaux et des frais annuels. Elles dépendent du chiffre d&#39;affaires du client selon le paragraphe suivant.</p>
                                        </li>
                                        <li data-list-text="4.1.2.2.2.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’élément déterminant pour le calcul de la redevance est le chiffre d’affaires consolidé du client réalisé sur le marché indépendant des pièces de rechange automobiles au cours de l’exercice précédant la facturation.</p>
                                        </li>
                                        <li data-list-text="4.1.2.2.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La redevance annuelle est ajustée chaque année en fonction du chiffre d&#39;affaires pertinent, conformément à la clause ci- dessus. Le client est tenu de communiquer à TecAlliance le chiffre d’affaires correspondant par écrit le 10 janvier au plus tard.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="4.1.2.3.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Échange des données</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client accepte que les données de l&#39;entreprise qu&#39;il saisit dans TecCom soient mises à la disposition d&#39;autres participants sous licence de TecCom.</p>
                                    </li>
                                    <li data-list-text="4.1.2.4.">
                                    <h1 style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Durée du contrat</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="#bookmark5" class="a">Par dérogation à l’article </a>1.8.3, la durée minimale du contrat est de trois</p>
                                    <ol id="l188">
                                        <li data-list-text="(3)">
                                            <p style="padding-top: 1pt;padding-left: 15pt;text-indent: -10pt;text-align: left;">ans.</p>
                                        </li>
                                    </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.2.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Connect 5</p>
                        <ol id="l189">
                            <li data-list-text="4.2.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Étendue des prestations</h1>
                                <ol id="l190">
                                    <li data-list-text="4.2.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans certains cas, TecAlliance fournit au client un logiciel qui doit être installé localement afin de connecter les systèmes du client à TecCom.</p>
                                    </li>
                                    <li data-list-text="4.2.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le logiciel est mis à la disposition du client sous forme de téléchargement.</p>
                                    </li>
                                    <li data-list-text="4.2.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit fournir le matériel et les logiciels nécessaires au fonctionnement du logiciel. Le client doit configurer et installer lui- même le logiciel.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.2.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l191">
                                    <li data-list-text="4.2.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">TecAlliance accorde au client un droit non exclusif, non transférable et non susceptible de sous-licence d’utiliser le logiciel</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">conformément aux dispositions des présentes conditions générales, limité dans le temps à la durée du contrat.</p>
                                    </li>
                                    <li data-list-text="4.2.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pendant la durée du contrat, le client est autorisé à copier tout ou partie du logiciel sous une forme écrite et / ou lisible par une machine afin de l’installer et / ou de l’exécuter.</p>
                                    </li>
                                    <li data-list-text="4.2.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Pendant la durée du contrat, le client est autorisé à</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">effectuer une copie du logiciel à des fins de sauvegarde et d’archivage.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.2.3.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Conséquence de la cessation du contrat</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de cessation du présent contrat, et quelle qu’en soit la raison, le client doit cesser, immédiatement et entièrement, toute exploitation du logiciel, supprimer toutes les copies existant dans ses systèmes et effacer ou remettre à TecDoc les éventuelles copies de sauvegarde.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.3.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Module Order</p>
                        <ol id="l192">
                            <li data-list-text="4.3.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l193">
                                    <li data-list-text="4.3.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à disposition du client l’exploitation du logiciel par un accès à distance par internet (Software-as-a-Service, SaaS).</p>
                                    </li>
                                    <li data-list-text="4.3.1.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si des commandes sont confirmées par e-mail, le client s’assure que la commande est envoyée via un domaine sécurisé Message Authentication, Reporting and Conformance (DMARC). Au demeurant,  TecAlliance  exclut  toute  responsabilité  pour  les commandes non identifiées. Le client supporte la charge de la preuve.</p>
                                    </li>
                                    <li data-list-text="4.3.1.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel est constamment développé et amélioré. Dans le cadre de ce développement, des fonctions partielles peuvent être modifiées ou supprimées, dans la mesure où ceci ne nuit pas à la bonne exécution du contrat pour le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.3.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l194">
                                    <li data-list-text="4.3.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel et les bases de données incluses sont protégés par le droit d’auteur. Le droit d’auteur, le droit des brevets, le droit des marques et tous les droits voisins sur le logiciel et les bases de données incluses sont la propriété exclusive de TecAlliance. Dans la mesure où les droits reviennent à des tiers, TecAlliance détient les droits d’exploitation correspondants.</p>
                                    </li>
                                    <li data-list-text="4.3.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans la mesure prévue par le présent contrat, TecAlliance accorde au client le droit simple, limité à la durée du présent contrat, non transmissible et non sous-licenciable, d’utiliser le logiciel et les bases de données incluses dans le cadre des fonctionnalités du logiciel. Des droits plus étendus ne sont pas accordés au client.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.4.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Paquets de module Order</p>
                        <ol id="l195">
                            <li data-list-text="4.4.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Via l&#39;interface du module Order, les utilisateurs peuvent commander  différents  paquets  qui  étendent  la  palette  de fonctionnalités proposée par le module. Les particularités de chacune des fonctionnalités se trouvent dans les descriptifs de la prestation. Le prix et la durée de validité de chaque paquet sont affichés à l&#39;utilisateur avant que la commande ne soit passée.</p>
                            </li>
                            <li data-list-text="4.4.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Conclusion du contrat</h1>
                                <ol id="l196">
                                    <li data-list-text="4.4.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Chaque commande d’un utilisateur est considérée comme une offre du client à TecAlliance visant à établir un contrat pour le paquet commandé.</p>
                                    </li>
                                    <li data-list-text="4.4.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dès réception de la commande par TecAlliance, le client et le destinataire de la facture recevront un e-mail confirmant la réception de la commande par TecAlliance et comprenant un récapitulatif de la commande (confirmation de commande).</p>
                                    </li>
                                    <li data-list-text="4.4.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">La confirmation de commande vaut acceptation de l’offre</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">par le client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.4.3.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Période d&#39;essai</h1>
                                <ol id="l197">
                                    <li data-list-text="4.4.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Lors de la première commande d&#39;un paquet, TecAlliance accorde au client une période d&#39;essai gratuite de 14 jours.</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    </li>
                                    <li data-list-text="4.4.3.2.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Pendant la période d&#39;essai, le client peut annuler la commande à tout moment sans avoir à en donner les raisons.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.4.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Durée du contrat</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="#bookmark5" class="a">Par dérogation à la clause </a>1.8.3, la durée minimale du contrat est d’un</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">(1) an.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.5.">
                        <p class="s1" style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Module ERP (SAP/Dynamics365)</p>
                        <ol id="l198">
                            <li data-list-text="4.5.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le contenu de la prestation est le développement d’une connexion de l’ERP du client (SAP/Dynamics365) à TecCom.</p>
                            </li>
                            <li data-list-text="4.5.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance accorde au client un droit d’usage simple, limité à la durée du contrat et à l’étendue du projet sur le code objet et le code source de la connexion.</p>
                            </li>
                            <li data-list-text="4.5.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En cas de cessation du contrat, quel qu’en soit le motif, il convient de suspendre toute utilisation du code objet et du code source et de supprimer toutes les copies dont dispose le client.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.6.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: left;">Frais de transaction</p>
                        <ol id="l199">
                            <li data-list-text="4.6.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Frais de transaction pour les clients (fabricants et acheteurs)</h1>
                                <ol id="l200">
                                    <li data-list-text="4.6.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: left;">Pour l’utilisation du module Order, le client (fabricant) paye</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">des frais de transaction à TecAlliance.</p>
                                    </li>
                                    <li data-list-text="4.6.1.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si le client (acheteur) utilise le module Order pour afficher la disponibilité des produits pour des tiers (boutique en ligne, etc.), le client (acheteur) paie les frais de transaction à TecAlliance.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.6.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Prestations fournies et base de calcul</h1>
                                <ol id="l201">
                                    <li data-list-text="4.6.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Toutes les transactions (demandes, commandes) sont payantes.</p>
                                    </li>
                                    <li data-list-text="4.6.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les transactions sur des organisations de test préalablement définies ne sont pas comptabilisées et ne sont pas soumises à des frais. L’utilisation d’organisations de test à des fins de production est interdite.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.6.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Fournisseurs tiers (GB, IE, FR)</h1>
                                <ol id="l202">
                                    <li data-list-text="4.6.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les transactions avec des clients (acheteurs) dont le siège social est en Grande-Bretagne, en Irlande, dans les possessions de la Couronne britannique, en France et les territoires français d’outre-mer, sont expressément exclues de l’accord et ne sont pas soumises aux frais de transaction mentionnés ci-dessus.</p>
                                    </li>
                                    <li data-list-text="4.6.3.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Ce service n’est expressément pas fourni et/ou facturé par</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">TecAlliance.</p>
                                    </li>
                                    <li data-list-text="4.6.3.3.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pour les transactions avec ces clients (acheteurs), des contrats distincts sont nécessaires avec les fournisseurs tiers dans les pays respectifs.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.7.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Module e-Invoicing</p>
                        <ol id="l203">
                            <li data-list-text="4.7.1.">
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est responsable de l’exactitude, de l’exhaustivité et de la conformité juridique des données et des fichiers de facturation PDF fournis.</p>
                            </li>
                            <li data-list-text="4.7.2.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance n’est pas tenue de vérifier l’exactitude du contenu et la conformité juridique des données et des fichiers de facturation PDF fournis. Il en va de même pour l&#39;identité entre les ensembles de données structurées et les fichiers de factures PDF fournis.</p>
                            </li>
                            <li data-list-text="4.7.3.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client autorise TecAlliance, en vertu de l&#39;exemption de l&#39;article 181 du Code civil allemand (BGB), à recevoir des factures pour lui, à vérifier les signatures, à créer les protocoles de test correspondants et à octroyer à cette fin des sous-autorisations.</p>
                            </li>
                            <li data-list-text="4.7.4.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les parties conviennent de considérer les fichiers de factures PDF comme des factures originales.</p>
                            </li>
                            <li data-list-text="4.7.5.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Frais de transaction facturation électronique</h1>
                                <ol id="l204">
                                    <li data-list-text="4.7.5.1.">
                                    <h1 style="padding-top: 1pt;padding-left: 31pt;text-indent: -25pt;text-align: justify;">Définitions</h1>
                                    <ol id="l205">
                                        <li data-list-text="4.7.5.1.1.">
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Selon la définition de TecCom, est considérée comme une transaction le transfert électronique d’un seul justificatif via la facturation électronique TecCom.</p>
                                        </li>
                                        <li data-list-text="4.7.5.1.2.">
                                            <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Selon la définition de TecCom, une facture individuelle est une facture mentionnant soit au plus une référence de commande et plusieurs références de livraison, soit au plus une référence de livraison et plusieurs références de commande.</p>
                                        </li>
                                        <li data-list-text="4.7.5.1.3.">
                                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Selon la définition de TecCom, une facture groupée est une facture mentionnant plusieurs références de commande et plusieurs références de livraison.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="4.7.5.2.">
                                    <h1 style="padding-left: 31pt;text-indent: -25pt;line-height: 8pt;text-align: justify;">Obligation de versement d’une redevance</h1>
                                    <ol id="l206">
                                        <li data-list-text="4.7.5.2.1.">
                                            <h1 style="padding-top: 1pt;padding-left: 37pt;text-indent: -31pt;text-align: justify;">Facturation des transactions</h1>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans le cadre de la facturation électronique, toutes les transactions sont soumises à une obligation de redevance conformément aux dispositions de la présente section. Les transactions sont facturées indépendamment du format de données choisi. Pour les transactions avec factures groupées, le nombre d’équivalents de factures individuelles selon la définition de TecCom est automatiquement déterminé par la facturation électronique TecCom et facturé en conséquence.</p>
                                        </li>
                                        <li data-list-text="4.7.5.2.2.">
                                            <h1 style="padding-left: 37pt;text-indent: -31pt;text-align: justify;">Exceptions</h1>
                                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les transactions effectuées par une organisation de test prédéfinie sont exclues de l’obligation de versement d’une redevance et ne seront pas incluses dans le calcul des redevances pour les transactions.</p>
                                        </li>
                                    </ol>
                                    </li>
                                    <li data-list-text="4.7.5.3.">
                                    <h1 style="padding-left: 32pt;text-indent: -26pt;line-height: 8pt;text-align: justify;">Interdiction d’utiliser les organisations de test à des fins de</h1>
                                    <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">production</h1>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">4.7.5. Toute utilisation d’organisations de test à des fins de production est interdite et peut entraîner le blocage immédiat du compte de facturation électronique.</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.8.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: left;">Implementation Services</p>
                        <ol id="l207">
                            <li data-list-text="4.8.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">Contenu des prestations</h1>
                                <ol id="l208">
                                    <li data-list-text="4.8.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 44pt;text-indent: -39pt;text-align: left;">Le contenu de la prestation est la fourniture de services de</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">conseil et de mise en œuvre dans le domaine TecCom.</p>
                                    </li>
                                    <li data-list-text="4.8.1.2.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Les prestations prévues dans cette section sont fournies en anglais ou en allemand.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.8.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Devoirs de collaboration du client</h1>
                                <ol id="l209">
                                    <li data-list-text="4.8.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu de mettre en place un accès fonctionnel, performant et en l&#39;état actuel de la technique (accès direct) au serveur de TecAlliance pour TecAlliance. L’autorisation d’accès est réglementée par TecAlliance en accord avec le client. A cette fin, le client indique à TecAlliance par écrit le nom d&#39; un responsable de projet anglophone ou germanophone comme interlocuteur et représentant qui peut être contacté pendant les heures de bureau habituelles pour répondre aux questions et qui est habilité à statuer sur les questions litigieuses.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.8.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Responsabilité</h1>
                                <ol id="l210">
                                    <li data-list-text="4.8.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La responsabilité de TecAlliance en cas de perte de données dans le cadre des prestations de services, effectuées conformément à la présente section, est limitée au travail de récupération typique qui aurait eu lieu, si le client avait effectué des copies de sauvegarde régulières et adaptées au risque.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="4.8.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contingents de prestations</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a href="#bookmark4" class="a">Les prestations au sens de la présente section peuvent être proposées sous forme de contingents de prestations. Par dérogation à l’article </a>1.5, la facturation est faite mensuellement en fonction des différentes prestations utilisées.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="4.9.">
                        <p class="s1" style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Market share analysis</p>
                        <ol id="l211">
                            <li data-list-text="4.9.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Étendue des prestations</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance peut fournir une prestation permettant au client de mesurer sa position de fournisseur sur la plateforme TecCom Trading dans certains pays et régions. Ceci repose sur l’analyse de données réelles de commande anonymes de TecCom, le client pouvant suivre sa part de marché pour divers groupes de produits.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                            <li data-list-text="4.9.2.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Autorisation d’usage</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Cette prestation est réservée aux utilisateurs de TecCom. Les données</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">d’accès ne peuvent pas être transmises à des tiers.</p>
                            </li>
                            <li data-list-text="4.9.3.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Protection des données</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">TecCom garantit l’anonymat des données et s’engage à respecter la</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">législation en vigueur relative à la protection des données.</p>
                            </li>
                            <li data-list-text="4.9.4.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Fourniture des données</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données sont fournies à intervalles variables que l’utilisateur peut définir lui-même. Les données peuvent être téléchargées via un serveur SFTIP sous forme de fichier CSV, de feuille de calcul Excel ou de rapport Power BI ou elles seront remises par e-mail.</p>
                            </li>
                            <li data-list-text="4.9.5.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Redevances</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Le client s’engage à verser les redevances conformément au modèle de</p>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">prix convenu.</p>
                            </li>
                            <li data-list-text="4.9.6.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Exclusion de responsabilité</h1>
                                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance ne répond pas de l’exactitude et de l’exhaustivité des données remises. Le recours à la prestation s’effectue aux risques des utilisateurs.</p>
                                <p style="text-indent: 0pt;text-align: left;"><br/></p>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </li>
                <li data-list-text="5.">
                    <p class="s19" style="padding-top: 4pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;"><a name="bookmark15">Conditions particulières de vente TecFleet</a></p>
                    <ol id="l212">
                        <li data-list-text="5.1.">
                        <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">SMART (TecCOO)</h1>
                        <ol id="l213">
                            <li data-list-text="5.1.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l214">
                                    <li data-list-text="5.1.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à disposition du client l’exploitation du logiciel par un accès à distance par internet (Software-as-a-Service, SaaS). L’accès au logiciel s’effectue par l’intermédiaire d’un portail web ou d’un service web.</p>
                                    </li>
                                    <li data-list-text="5.1.1.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Les détails relatifs au logiciel mis à disposition figurent</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">dans le descriptif de la prestation et dans l’offre.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.1.2.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droits d’usage</h1>
                                <ol id="l215">
                                    <li data-list-text="5.1.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client reconnaît tous les droits d’auteur de TecAlliance sur les données, informations et systèmes mis à disposition dans le cadre du contrat et/ou dont il a connaissance. TecAlliance possède tous les droits de licence et d’exploitation sur les données/logiciels utilisés.</p>
                                    </li>
                                    <li data-list-text="5.1.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Le droit d’exploitation s’étend à la zone de marché des pays choisis dans l’offre.</p>
                                    </li>
                                    <li data-list-text="5.1.2.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les données doivent toujours être consultées par l’intermédiaire du service web mis à disposition par TecAlliance. Tout stockage temporaire des données ou des informations chez le client et l’utilisation multiple des données qui en résulte sont expressément interdits.</p>
                                    </li>
                                    <li data-list-text="5.1.2.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toute utilisation et réutilisation excédant le cadre de l’utilisation prévue au contrat doit faire l’objet d’une autorisation spécifique.</p>
                                    </li>
                                    <li data-list-text="5.1.2.5.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit s’assurer que les tiers non autorisés et toute personne ayant quitté son entreprise n’ont pas la possibilité d&#39;utiliser les données et les informations.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.1.3.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Réclamation pour vices, responsabilité</h1>
                                <ol id="l216">
                                    <li data-list-text="5.1.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance remédie à tous les défauts affectant le contenu des données et des fonctions mises à disposition dans un délai raisonnable suivant la réception d’un descriptif de l’erreur écrit. En cas d’impossibilité, le client peut demander une réduction du prix, à l’exclusion de tout autre droit. En cas de défauts considérables répétés, le client peut en outre résilier le contrat sans préavis. Toute action pour vice est exclue si elle repose sur des circonstances imputables au client.</p>
                                    </li>
                                    <li data-list-text="5.1.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Malgré une planification et un travail soigneux, il ne peut être exclu, dans tous les cas, que des informations ou des résultats soient incorrects lors de la production de données, du transfert de savoir-faire ou de processus informatiques. Ainsi, la production et la fourniture s’effectuent sur la base des informations les plus pertinentes dont nous disposons et à la condition que les données sources, telles que par ex. les informations du fabricant, soient correctes. En conséquence, TecAlliance exclut toute responsabilité en cas de données ou de résultats incorrects dus à des erreurs des données et informations fournies par des tiers à TecAlliance. Ceci n’affecte pas la responsabilité pour dol ou négligence grave.</p>
                                    </li>
                                    <li data-list-text="5.1.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Au demeurant, TecAlliance répond uniquement des simples négligences en cas de violation d&#39;une obligation indispensable à la réalisation de l’objet du contrat (obligations substantielles). S’agissant des simples négligences, la responsabilité de TecAlliance est limitée au dommage prévisible propre au contrat et au prix de souscription de la licence (licence annuelle). En cas d’impossibilité initiale, la responsabilité de TecAlliance est engagée uniquement s’il avait connaissance de l’obstacle à l’exécution ou s’il n’en avait pas connaissance suite à une négligence grave. Les limitations de responsabilité ci-dessus ne s’appliquent ni aux actions reposant sur la responsabilité du fait des produits défectueux, ni aux atteintes à la vie, à l’intégrité corporelle et à la santé.</p>
                                    </li>
                                    <li data-list-text="5.1.3.4.">
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance ne peut répondre de la compatibilité du système chez le client. Le client répond seul, avant la conclusion du contrat, de l’examen de la fonctionnalité des données mises à disposition à l’aide des fichiers de la base de données fournis et du .Net Assembly pour l’accès aux bases de données. À la conclusion du contrat, le client confirme avoir effectué l’examen qui s’impose. Toute adaptation du format ou des services en ligne n’est pas partie intégrante du présent accord et doit être commandée séparément par le client. Toute absence de fonctionnalité ou toute fonctionnalité restreinte (par ex. en raison d’une compatibilité insuffisante du système) ou tout retard d’intégration des données mises à disposition dans l’application du client ne libère pas le client des obligations prévues par le présent contrat.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.1.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Durée du contrat, résiliation</h1>
                                <ol id="l217">
                                    <li data-list-text="5.1.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;"><a href="#bookmark5" class="a">Par dérogation à l’article </a>1.8.3, la durée minimale du contrat est de trois (3) ans.</p>
                                    </li>
                                    <li data-list-text="5.1.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 118%;text-align: justify;"><a href="#bookmark5" class="a">Par dérogation à l’article </a>1.8.3, la durée minimale du délai de préavis est de six (6) mois.</p>
                                    </li>
                                    <li data-list-text="5.1.4.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;"><a href="#bookmark1" class="a">Par dérogation à l’article </a><a href="#bookmark1">1.2.1.</a></p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.1.5.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Publicité, utilisation des marques, droits d’auteur</h1>
                                <ol id="l218">
                                    <li data-list-text="5.1.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les contractants peuvent utiliser les marques et les noms de produit du contractant à des fins publicitaires. À cette fin, le client doit remettre à TecAlliance le logo de son entreprise deux (2) semaines au plus tard après la conclusion du contrat, sous forme de graphique vectoriel en couleur.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.1.6.">
                                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dans son application, le client est tenu d’apposer le logo de TecAlliance et le claim « TecAlliance inside » sur les données provenant de TecAlliance. À cet égard, il convient de tenir compte du concept de CI et la publication de marques et de signes s’effectue d’un commun accord. Toutefois, toute intention d’utilisation doit être indiquée au contractant en temps utile. Il n’est pas nécessaire de demander un consentement à l’utilisation.</p>
                            </li>
                        </ol>
                        </li>
                        <li data-list-text="5.2.">
                        <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">QUICK (TecAudit)</h1>
                        <ol id="l219">
                            <li data-list-text="5.2.1.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Contenu des prestations</h1>
                                <ol id="l220">
                                    <li data-list-text="5.2.1.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance met à la disposition du client le comparateur web QUICK par un accès à distance par internet à titre de Software-as- a-Service.</p>
                                    </li>
                                    <li data-list-text="5.2.1.2.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">Les détails relatifs au logiciel mis à disposition figurent</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">dans le descriptif de la prestation et dans l’offre.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.2.2.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Obligations du client</h1>
                                <ol id="l221">
                                    <li data-list-text="5.2.2.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client doit aider TecAlliance à recevoir du fabricant ou de l’importateur les données et prix nécessaires spécifiques à chaque pays. Si elles sont disponibles, TecAlliance reçoit des données et des informations dites de prélancement afin de pouvoir les intégrer dans la solution TecAlliance aussi tôt que possible, dans l’idéal avant le lancement du produit sur le marché.</p>
                                    </li>
                                    <li data-list-text="5.2.2.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 120%;text-align: justify;">Toutes les discussions techniques et les documentations des définitions sont menées et rédigées en anglais.</p>
                                    </li>
                                    <li data-list-text="5.2.2.3.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;line-height: 8pt;text-align: justify;">Le licencié est responsable des formations des partenaires</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">de services, de leurs prospection et de l’assistance téléphonique.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.2.3.">
                                <h1 style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droits d’exploitation</h1>
                                <ol id="l222">
                                    <li data-list-text="5.2.3.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client reçoit le droit non exclusif et limité à la durée du contrat d’utiliser le logiciel pour réaliser les objectifs du contrat. La licence est délivrée pour les domaines d’utilisation suivants : consultation et utilisation du système, consultation et impression des créances, élaboration de règles et configuration de base.</p>
                                    </li>
                                    <li data-list-text="5.2.3.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toute duplication, diffusion ou mise à disposition publique de la base de données en ligne ou d’une partie de la base de données essentielle pour le cadre ou le volume, nécessite le consentement</p>
                                    <p style="text-indent: 0pt;text-align: left;"><br/></p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">préalable de TecAlliance si elle excède le cadre du contrat. La duplication, la diffusion ou la mise à disposition publique d’une partie de la base de données essentielle pour le cadre ou le volume est assimilée à la duplication, la diffusion ou la mise à disposition publique répétée et systématique de parties de la base de données non essentielles au cadre et au volume, si ces actes vont à l’encontre d’une utilisation normale de la base de données ou nuisent aux intérêts de TecAlliance. Notamment, le client ne peut pas utiliser la base de données pour créer sa propre base de données sous forme électronique ou sous toute autre forme. Les Parties conviennent que TecAlliance remet au client et aux partenaires de services, dans le cadre du logiciel, une interface que le client et le partenaire de service peuvent et ont le droit d’utiliser pour saisir, stocker, dupliquer, diffuser et reproduire ouvertement les données dont ils ont besoin pour leur propre système de facturation. À cet égard, le client répond des éventuelles adaptations nécessaires des interfaces.</p>
                                    </li>
                                    <li data-list-text="5.2.3.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance et le client conviennent qu’à la livraison de la solution contractuelle de TecAlliance sous le nom de marque du client (labeling), les actions fondées sur les droits d’auteur de TecAlliance ne s’en trouvent pas affectées. Au terme du contrat, le client ne peut plus autoriser, organiser ou développer l’utilisation de la solution contractuelle TecAlliance. Si le client intervient, dans ces cas précis, en qualité de fournisseur de systèmes, il n’acquiert aucun droit de propriété sur la base de données, les données ou tout autre résultat obtenu par l’analyse de la base de données. Le client peut continuer d’utiliser les protocoles et les analyses individuelles du parc automobile au terme du contrat, si le système ne doit pas être soumis à un traitement informatique.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.2.4.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;text-align: justify;">Droits d’auteur/Confidentialité</h1>
                                <ol id="l223">
                                    <li data-list-text="5.2.4.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le logiciel est la propriété exclusive de TecAlliance. Le logiciel utilisé est protégé par des droits d’auteur conformément aux dispositions sur la protection des programmes informatiques. La protection des droits d’auteur s’étend notamment au code du programme, à la documentation graphique et sur les contenus, l’apparence extérieure, la structure et l’organisation des fichiers du programme, les noms des programmes, les logos et autres représentations qui se trouvent dans le logiciel.</p>
                                    </li>
                                    <li data-list-text="5.2.4.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance garantit que le logiciel utilisé, y compris le paquet de données et la bibliothèque de programmes, sont exempts de droits de tiers ou que les sous-licences ou autorisations nécessaires ont été délivrées. Si des tiers invoquent de tels droits, TecAlliance fera tout son possible pour défendre l’application, à ses propres frais, contre les droits invoqués par les tiers. Le client informe TecAlliance sans délai par écrit si des tiers font valoir de tels droits et délivre à TecAlliance toutes les procurations et pouvoirs nécessaires à défendre l&#39;application contre les droits invoqués par les tiers.</p>
                                    </li>
                                    <li data-list-text="5.2.4.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le client est tenu d’utiliser les données fournies et le savoir- faire de TecAlliance qui s’y rapporte exclusivement aux fins de réalisation des objectifs du contrat. Toute transmission de données et de savoir-faire, en tout ou en partie, non prévue par le contrat requiert le consentement préalable écrit de TecAlliance. Toute forme de revente, d’identification, de création de nouveaux logiciels, de commercialisation sous un autre nom ou de vente de données et systèmes, en tout ou en partie, non prévues par le contrat requièrent l’autorisation préalable écrite de TecAlliance.</p>
                                    </li>
                                    <li data-list-text="5.2.4.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les deux Parties doivent convenir de l’indication de provenance ou des éditeurs avant la publication. En principe, les contractants peuvent toutefois utiliser les marques et noms de produit du cocontractant. À cet égard, il convient de tenir compte du concept de</p>
                                    <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">CI, et les marques et leur emblème peuvent uniquement être publiés sur</p>
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">la base d’un accord entre les deux Parties.</p>
                                    </li>
                                    <li data-list-text="5.2.4.5.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance  s’assure  que  toutes  les  données  et informations reçues du client (notamment les donnés de clients gestion de parcs automobiles (FPM) du client) restent confidentielles, qu’elles ne soient pas transmises à des tiers non autorisés et qu’elles soient traitées conformément aux lois en vigueur sur la protection des données. En outre, TecAlliance garantit la sécurité et la confidentialité de ces données, tant en dehors du système que dans le système, notamment à l’égard des utilisateurs qui n’ont pas de droits d’accès aux données du client.</p>
                                    </li>
                                </ol>
                            </li>
                            <li data-list-text="5.2.5.">
                                <h1 style="padding-left: 25pt;text-indent: -19pt;line-height: 8pt;text-align: justify;">Responsabilité et garantie</h1>
                                <ol id="l224">
                                    <li data-list-text="5.2.5.1.">
                                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Malgré une diligence maximale au regard de la saisie et de la génération de données, du transfert de savoir-faire et des processus informatiques, il n’est pas possible de garantir l’absence totale d’informations incorrectes. Le processus de création d’une livraison de produits de TecAlliance s’effectue en garantissant au mieux l’exactitude des données sources, à savoir des informations OE. Ainsi, TecAlliance exclut toute responsabilité reposant sur des données et des informations incorrectes remises à TecAlliance par des tiers. La responsabilité pour faute intentionnelle et négligence grave ne s’en trouve pas affectée. Dans tous les cas, la responsabilité s’apprécie au regard de la loi et elle est limitée à la valeur du bien, du service et de la fourniture de données. Si TecAlliance recourt à des sous-traitants pour satisfaire à ses obligations contractuelles, il en répond de la même manière qu’il répond de ses employés.</p>
                                    </li>
                                    <li data-list-text="5.2.5.2.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">TecAlliance  exclut  toute  responsabilité  pour l’interopérabilité des systèmes. Le client s’engage à vérifier la fonctionnalité des données fournies par TecAlliance via des services web dans son environnement de produits et de systèmes avant la conclusion du contrat. En signant le contrat, le client confirme avoir effectué cette vérification. Toute adaptation des formats ou services en ligne ne fait pas l’objet du présent contrat et doit être commandée séparément par le client. La non-disponibilité, l’utilisation limitée (par ex. en raison de défaillances du système du client), le retard d’intégration des données et informations remises dans l’application du client ne libèrent pas le client des obligations prévues par le présent contrat.</p>
                                    </li>
                                    <li data-list-text="5.2.5.3.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le délai de prescription des actions en réparation à l’encontre de TecAlliance est d’une année à compter du début du délai de prescription légal.</p>
                                    </li>
                                    <li data-list-text="5.2.5.4.">
                                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les actions en garantie ou en réparation doivent être déclarées à TecAlliance sous forme de texte dans un délai de 14 jours à compter de la connaissance du motif de l’action.</p>
                                    </li>
                                    <li data-list-text="5.2.5.5.">
                                    <p style="padding-left: 44pt;text-indent: -39pt;text-align: justify;">En cas de non-respect de l’obligation précédente, toute</p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        </li>
                    </ol>
                </li>
            </ol>
            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">autre action en garantie ou en réparation est exclue.</p>

            <ElementsToolBar>
                <template #right>
                    <ElementsButtonLinear name="Accepter" @click="accept()"/>
                    <ElementsButtonLinear name="Refuser" class="btn-ghost" @click="disconnect()"/>
                </template>
            </ElementsToolBar>
        </template>
    </ElementsModal>
</template>

<style scoped lang="scss">
.tecAllianceCgt {
    & h1 {
        font-size: 1rem;
    }   
    & .btn-ghost {
        padding: 7px 10px;
        width: auto;
    }
}
</style>