<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useUserStore } from '~/store/useUserStore';
import type { VehiclePlateData } from '~/types/interfaces';
const { navbarApp } = useUIApp()
const { establishment, entity, links } = useAppConfig();
const { isSearchVehicleOpen, isMobileNavBarOpen } = useDashboard()
const userStore = useUserStore();
const config = useRuntimeConfig()
const vehiclePlateData: Ref<VehiclePlateData> = useCookie(config.public.cookies.vehiclePlateData);
const { width } = useWindowSize()
const router = useRouter();

const linksStates = ref(new Map<string, boolean>())
const BOUTIQUE_NAME = "Boutique";

const toggleCurrentLinkDropdown = (link: string, nameCategory: string, linkUrl: string) => {
    const isOpen = linksStates.value.get(link) || false;

    if (nameCategory === BOUTIQUE_NAME) {
        if (!isOpen) {
            linksStates.value.set(link, true);
            router.push(linkUrl);
        } else {
            linksStates.value.set(link, false);
        }
        return;
    }

    linksStates.value.set(link, !isOpen);
}
const isDropdownLinkOpen = (link: string) => {
    return linksStates.value.get(link) || false;
}

const toggleVehicleSearch = (isVehicleNeeded: boolean | undefined) => {
    if (isVehicleNeeded && !vehiclePlateData.value) {
        isSearchVehicleOpen.value = true
        return
    }
}

</script>

<template>
    <div
        :class="['min-h-dvh', 'min-w-56', 'max-w-64', 'navbarApp', 'flex', 'flexColumn', { mobilNavbar: width <= 1000 }, { open: isMobileNavBarOpen }]">
        <div class="headerNav">
            <NuxtLink class="max-w-64 headerNav-link" :to="links.dashboard" :title="establishment.companyName">
                <NuxtImg :src="`${links.cdnUrl}/${entity.id}/${establishment.id}/images/logo.png`"
                    placeholder="/img/missing-img.png" class="max-w-40 max-h-20" />
            </NuxtLink>
            <p class="text-sx main-color w-600" v-if="establishment.configuration.showBranchName">{{
                userStore?.user?.succursalle?.succursalleName }}</p>
            <ElementsIcon v-if="width <= 1000" @click="isMobileNavBarOpen = false" icon="xmark" />
        </div>

        <ElementsDivider class="m1 mo me" />

        <nav>
            <ul class="p0">
                <li v-for="(link, linkIndex) in navbarApp" :key="link.nameCategory">
                    <NuxtLink :class="['appLinks', 'w-600', 'text-sm', { 'disabled-link': link.disabled }]"
                        v-if="!link.subCategories.length"
                        :to="link.disabled || (link.isVehicleNeeded && !vehiclePlateData) ? '' : link.link"
                        @click="toggleVehicleSearch(link.isVehicleNeeded)">
                        <ElementsIcon :icon="link.imageCategory" /> {{ link.nameCategory }}
                    </NuxtLink>

                    <div v-else>
                        <button :class="['appLinks', 'w-600', 'text-sm', { 'disabled-link': link.disabled }]"
                            type="button"
                            @click="toggleCurrentLinkDropdown(`${link.idCategory}_${link.nameCategory}_${linkIndex}`, link.nameCategory, link.link)">
                            <ElementsIcon :icon="link.imageCategory" /> {{ link.nameCategory }}
                            <ElementsIcon
                                :class="['iconSmall', 'moauto', 'angleLinks', { 'rotate-right': isDropdownLinkOpen(`${link.idCategory}_${link.nameCategory}_${linkIndex}`) }]"
                                icon="fa-angle-right" />
                        </button>
                        <ElementsDropdown
                            :isOpen="isDropdownLinkOpen(`${link.idCategory}_${link.nameCategory}_${linkIndex}`)">
                            <template #content>
                                <ul class="p0">
                                    <li v-for="subLink in link.subCategories" :key="subLink.nameSubCategory">
                                        <NuxtLink
                                            :class="['appLinks', 'w-600', 'appLinks-subLinks', 'text-sm', { 'disabled-link': subLink.disabled }]"
                                            :to="!subLink.disabled ? subLink.linkSubCategory : ''">
                                            <ElementsIcon class="iconSmall" icon="fa-angle-right" /> {{
                                                subLink.nameSubCategory }}
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </template>
                        </ElementsDropdown>
                    </div>
                </li>
            </ul>
        </nav>

        <div class="flex flex1">
            <NavHorizontale v-if="width <= 1000" />
        </div>
        <NuxtLink class="deconnection-button" :to="links.login" title="Déconnexion">
            <ElementsIcon icon="fa-regular-circle-xmark" /> Déconnexion
        </NuxtLink>
    </div>
</template>


<style scoped lang="scss">
.navbarApp {
    background-color: var(--secondary-background-color);
    overflow-y: auto;
}

.mobilNavbar {
    transform: translateX(-100%);
    transition: transform var(--animation-navbar-duration) ease-in-out;

    &.open {
        transform: translateX(0);
    }
}

.headerNav {
    padding: 2.5rem 1.75rem 0 1.75rem;
    position: relative;

    &-link {
        display: block;
        margin-bottom: 0.625rem;

        &.router-link-exact-active {
            background-color: transparent;
        }
    }

    & svg {
        position: absolute;
        top: 1rem;
        right: 1rem;
        --icon-size-default: 2rem;
        cursor: pointer;
        transition: color var(--animation-site-duration) linear;

        &:hover {
            color: var(--mainColor);
        }
    }
}

.appLinks {
    display: flex;
    color: var(--secondaryColor);
    text-decoration: none;
    gap: .75rem;
    align-items: center;
    padding: .5rem 1.75rem;
    border: none;
    border-left: 0.1875rem transparent solid;
    cursor: pointer;
    background-color: transparent;
    width: 100%;

    &-subLinks {
        padding: .5rem 1.75rem .5rem 2.5rem;

    }

    &.disabled-link {
        background-color: transparent;
        border-color: transparent;
        color: var(--neutral71);
        cursor: default;
    }

    &:hover:not(.disabled-link) {
        background-color: var(--shades76);
        border-color: var(--mainColor);
        color: var(--mainColor);
    }
}

.router-link-exact-active {
    background-color: var(--shades76);
    border-color: var(--mainColor);
    color: var(--mainColor);
}

.angleLinks {
    transition: transform var(--animation-site-duration) linear;
}

.rotate-right {
    transform: rotateZ(90deg);
}

.deconnection-button {
    color: var(--mainColor);
    border: 2px var(--mainColor) solid;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
    letter-spacing: -0.01rem;
    border-radius: var(--roundedBorder);
    width: fit-content;
    padding: .625rem 1rem;
    margin: 1rem;
    transition: all var(--animation-site-duration) linear;

    &:hover {
        background-color: var(--mainColor);
        color: var(--defaultFontColor);
    }
}

@media all and (max-width: 1000px) {
    .navbarApp {
        position: fixed;
        inset: 0;
        z-index: 11000;
    }

    .deconnection-button {
        margin: 2rem auto 1rem auto;
    }
}
</style>